import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import Cookies from 'js-cookie'

const CSRF_TOKEN = 'xsrf-token'

export class ApiRequest {
  private url?: AxiosRequestConfig['url']
  private method?: AxiosRequestConfig['method']
  private data?: AxiosRequestConfig['data']
  private headers?: AxiosRequestConfig['headers']
  private cancelToken?: AxiosRequestConfig['cancelToken']

  constructor() {
    this.headers = {}

    const xsrfToken = Cookies.get(CSRF_TOKEN)
    if (xsrfToken) this.headers['xsrf-token'] = xsrfToken
  }

  getUrl = (): AxiosRequestConfig['url'] => this.url
  getMethod = (): AxiosRequestConfig['method'] => this.method
  getData = (): AxiosRequestConfig['data'] => this.data
  getHeaders = (): AxiosRequestConfig['headers'] => this.headers

  get(url: string) {
    this.method = 'get'
    this.url = url
    return this
  }

  post(url: string) {
    this.method = 'post'
    this.url = url
    return this
  }

  patch(url: string) {
    this.method = 'patch'
    this.url = url
    return this
  }

  delete(url: string) {
    this.method = 'delete'
    this.url = url
    return this
  }

  withData(data: AxiosRequestConfig['data']) {
    this.data = data
    return this
  }

  withCancelToken(cancelToken: AxiosRequestConfig['cancelToken']) {
    this.cancelToken = cancelToken
    return this
  }

  withHeaders(headers: AxiosRequestConfig['headers']) {
    Object.assign(this.headers || {}, headers)
    return this
  }

  send(): Promise<AxiosResponse> {
    return axios.request({
      method: this.method,
      url: this.url,
      data: this.data,
      headers: this.headers,
      cancelToken: this.cancelToken
    })
  }
}
