import { expiredModalStyle } from './Modal/ModalStyles'

import { ActionButton } from '@atbdigitalteam/obs-shared-components'
import React, { ReactNode } from 'react'
import { Modal } from './Modal/Modal'

export interface ExpiredPageModalProps {
  open: boolean
  modalBody?: ReactNode
  modalButtonText: string
  onModalButtonClick: () => void
  onClose?: () => void
  defaultDividers?: boolean
  displayCloseButton?: boolean
  mixpanelPage?: string
}

export const ExpiredPageModal = ({
  open,
  displayCloseButton,
  modalBody,
  modalButtonText,
  onModalButtonClick
}: ExpiredPageModalProps) => {
  const buttonStyle = {
    textAlign: 'center',
    whiteSpace: 'nowrap',
    paddingLeft: '3em',
    paddingRight: '3em',
    marginTop: '16px'
  } as React.CSSProperties

  const modalTitle = 'Your session has expired'

  const modalFooter = (
    <ActionButton
      buttonText={modalButtonText}
      type='submit'
      data-testid='expired-modal-btn'
      onClick={onModalButtonClick}
      style={buttonStyle}
    />
  )

  return (
    <Modal
      data-testid='modal'
      open={open}
      modalTitle={modalTitle}
      modalBody={modalBody}
      modalFooter={modalFooter}
      defaultDividers={false}
      displayCloseButton={displayCloseButton}
      modalStyle={expiredModalStyle}
    />
  )
}
