function checkUserAgentExists(userAgentString: string, userAgent: string) {
  return userAgentString.indexOf(userAgent) > -1
}

function checkUserAgentDoesNotExist(userAgentString: string, userAgent: string) {
  return userAgentString.indexOf(userAgent) <= -1
}

function detectCookies() {
  if (navigator.cookieEnabled) return true
  // Attempt to create a test cookie
  document.cookie = 'cookietest=1'
  const foundCookie = document.cookie.indexOf('cookietest=') !== -1
  // Delete test cookie
  document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT'
  return foundCookie
}

export function detectUnsupportedBrowser() {
  const userAgentString = navigator.userAgent
  const IExplorerAgent =
    checkUserAgentExists(userAgentString, 'MSIE') ||
    (checkUserAgentExists(userAgentString, 'rv') && checkUserAgentDoesNotExist(userAgentString, 'Firefox'))
  const ucAgent = checkUserAgentExists(userAgentString, 'UCBrowser')
  const cookiesAllowed = detectCookies()

  return IExplorerAgent || ucAgent || !cookiesAllowed
}
