import { makeStyles, Theme } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import RoomIcon from '@material-ui/icons/Room'
import NotesIcon from '@material-ui/icons/Notes'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'

import { AppointmentSubTypeEnum, Branch, MeetingMethodEnum } from '@atbdigitalteam/obs-shared-components'
import { MeetingComponents } from '../../utils/meetingMethod'

const CHANGE_LOCATION_TEXT = 'Change'

export interface AppointmentInfoCardProps {
  serviceSubType: AppointmentSubTypeEnum | undefined
  serviceSubTypeLabel: string
  onChangeServiceSubTypeClick: () => void
  meetingMethod: MeetingMethodEnum | undefined
  meetingMethodLabel: string
  onChangeMeetingMethodClick: () => void
  branch: Branch | null
  branchLabel: string
  onChangeBranchClick: () => void
}

const useStyles = makeStyles<Theme, AppointmentInfoCardProps>({
  title: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px'
  },
  card: {
    alignItems: 'center',
    marginTop: '8px',
    borderColor: '#75757B',
    padding: '16px 20px 16px 12px'
  },
  changeButton: {
    fontWeight: 600,
    lineHeight: '20px',
    float: 'right',
    padding: '8px ',
    border: 'solid 2px transparent',
    '&:focus-visible,&:hover': {
      border: 'solid 2px #000025',
      backgroundColor: 'transparent'
    }
  },
  subTitle: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    margin: '8px 0px'
  },
  description: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px'
  },
  icon: {
    margin: '8px 9.5px 8px 0px',
    fontSize: '18px',
    verticalAlign: 'middle'
  },
  grid: {
    '&:not(:first-child)': {
      marginTop: '20px'
    },
    '&:not(:last-child)': {
      marginBottom: '20px'
    }
  },
  labelRow: {
    marginBottom: '4px'
  },
  branchAddress: {
    lineHeight: '18px',
    fontSize: '12px',
    paddingTop: '4px'
  },
  content: {
    maxWidth: '260px'
  }
})

export const AppointmentInfoCard = (props: AppointmentInfoCardProps) => {
  const classes = useStyles(props)
  const {
    serviceSubType,
    serviceSubTypeLabel,
    onChangeServiceSubTypeClick = () => {
      // noop
    },
    meetingMethod,
    meetingMethodLabel,
    onChangeMeetingMethodClick = () => {
      // noop
    },
    branch,
    branchLabel,
    onChangeBranchClick = () => {
      // noop
    }
  } = props

  interface AppointmentInfoProps {
    label: string
    description?: string | AppointmentSubTypeEnum
    onClick: () => void
    icon: JSX.Element
    branchAddress?: string
  }

  const AppointmentInfo = (props: AppointmentInfoProps) => {
    const { label, description, onClick, icon, branchAddress } = props

    return (
      <Grid
        container
        direction='column'
        wrap='nowrap'
        className={classes.grid}
        data-testid={`appointment-info-card-${label}`}
      >
        <Grid container className={classes.labelRow}>
          <Grid item>{icon}</Grid>
          <Grid item>
            <Typography className={classes.subTitle}>{label}</Typography>
          </Grid>
          <Grid item xs>
            <Button
              data-testid={`change-${label}-button`}
              aria-label={`change-${label}`}
              className={classes.changeButton}
              color='primary'
              variant='text'
              onClick={onClick}
              endIcon={<EditOutlinedIcon style={{ fontSize: '16px' }} />}
              disableRipple
            >
              {CHANGE_LOCATION_TEXT}
            </Button>
          </Grid>
        </Grid>
        <Grid container wrap='nowrap'>
          <Grid item>
            <Grid item className={classes.content}>
              <Typography className={classes.description}>{description}</Typography>
            </Grid>
            {branchAddress && (
              <Grid item className={classes.content}>
                <Typography className={classes.branchAddress}>{branchAddress}</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const { icon: meetingIcon, text: meetingText } = MeetingComponents(classes.icon, meetingMethod)
  return (
    <div>
      <Typography className={classes.title} component='h2'>
        Appointment Information
      </Typography>
      <Card className={classes.card} data-testid='appointment-info-card' variant='outlined'>
        <AppointmentInfo
          label={serviceSubTypeLabel}
          description={serviceSubType}
          onClick={onChangeServiceSubTypeClick}
          icon={<NotesIcon className={classes.icon} />}
        />
        {meetingMethod && (
          <AppointmentInfo
            label={meetingMethodLabel}
            description={meetingText}
            onClick={onChangeMeetingMethodClick}
            icon={meetingIcon}
          />
        )}
        {branch && (
          <AppointmentInfo
            label={branchLabel}
            description={branch.name}
            branchAddress={branch.address}
            icon={<RoomIcon className={classes.icon} />}
            onClick={onChangeBranchClick}
          />
        )}
      </Card>
    </div>
  )
}
