// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
const fixedEncodeURIComponent = (str: string) =>
  encodeURIComponent(str).replace(/[!'()*]/g, c => `%${c.charCodeAt(0).toString(16).toUpperCase()}`)

export class QueryParams {
  private params: { [key: string]: string | number | boolean }

  constructor(params: { [key: string]: string | number | boolean } = {}) {
    this.params = {
      ...params
    }
  }

  append(params: { [key: string]: string | number | boolean }) {
    Object.assign(this.params, params)
    return this
  }

  toString() {
    return Object.entries(this.params)
      .map(([key, value]) => `${fixedEncodeURIComponent(key)}=${fixedEncodeURIComponent(`${value}`)}`)
      .join('&')
  }
}
