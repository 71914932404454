import { Branch } from '@atbdigitalteam/obs-shared-components'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Radio from '@material-ui/core/Radio'
import Typography from '@material-ui/core/Typography'
import React from 'react'

export interface BranchCardProps {
  branch: Branch | null
  checked?: boolean
  label?: string
  onChangeClick?: never
  onChange?: (value: Branch | null) => void
  disabled?: boolean
}

const useStyles = makeStyles<Theme, BranchCardProps>({
  branchCard: {
    width: '100%'
  },
  card: ({ checked }) => ({
    display: 'grid',
    gridTemplateColumns: '34px minmax(210px, max-content) auto',
    gridTemplateRows: 'minmax(32px, max-content) minmax(50px, max-content)',
    alignItems: 'center',
    marginTop: '8px',
    minHeight: '64px',
    border: checked ? '2px solid #0072F0' : '1px solid #75757B'
  }),
  cardDisabled: {
    border: '1px solid #A7A7AD'
  },
  branch: {
    gridColumn: '2',
    margin: '16px 5px 2px 16px'
  },
  branchAddress: {
    margin: '2px 40px 16px 16px',
    lineHeight: '18px',
    gridRow: '2',
    gridColumn: '2',
    alignSelf: 'normal'
  },
  branchName: {
    fontWeight: 600,
    gridRow: '1',
    gridColumn: '2'
  },
  distance: {
    gridColumn: '3',
    marginRight: '16px',
    marginTop: '16px',
    textAlign: 'right'
  },
  distanceText: {
    display: 'inline-block',
    fontWeight: 400,
    lineHeight: '18px',
    textAlign: 'right',
    '@media only screen and (max-width: 375px)': {
      width: '65px'
    }
  },
  availability: ({ branch }) => ({
    fontWeight: 600,
    lineHeight: '18px',
    gridRow: '2',
    gridColumn: '3',
    color: branch?.available ? '#00855D' : '#EB0042',
    marginRight: '16px',
    marginTop: '2px',
    alignSelf: 'normal',
    textAlign: 'right'
  }),
  radioRoot: {
    gridColumn: '1',
    padding: '0',
    margin: '0 0 0 12px',
    gridRowStart: 'span 2'
  },
  disabledText: {
    color: '#93939A'
  }
})

export const BranchCard = (props: BranchCardProps) => {
  const classes = useStyles(props)
  const {
    branch,
    checked,
    label,
    onChange = () => {
      // noop
    },
    disabled
  } = props
  const distanceAway = branch?.distance ? `${Math.round(branch.distance * 10) / 10}\u00A0km away` : undefined

  const branchAvailability = branch?.available
    ? branch?.limited
      ? 'Limited appointments'
      : 'Multiple appointments'
    : 'No appointments'

  const handleChange = () => onChange(branch)
  const radioId = `branch-card-${branch?.transit}-radio`

  return (
    <div
      className={classes.branchCard}
      data-testid={`branch-card-${branch?.transit}`}
      onClick={disabled ? undefined : handleChange}
    >
      {label && (
        <Typography data-testid={`branch-card-${branch?.transit}-label`} variant='body2'>
          {label}
        </Typography>
      )}
      <Card className={`${classes.card} ${disabled ? classes.cardDisabled : ''}`} variant='outlined'>
        <Radio
          data-testid={radioId}
          id={radioId}
          size='small'
          inputProps={{
            'aria-label': disabled
              ? `${branch?.name} doesn't have availability for the services you've requested`
              : `${branch?.name}, ${distanceAway}`,
            'aria-selected': checked,
            'aria-disabled': disabled
          }}
          checked={checked}
          color='primary'
          classes={{
            root: classes.radioRoot
          }}
        />
        <div className={classes.branch}>
          <label htmlFor={radioId}>
            <Typography variant='body1' className={`${classes.branchName} ${disabled ? classes.disabledText : ''}`}>
              {branch?.name}
            </Typography>
          </label>
        </div>
        <Typography
          className={`${classes.branchAddress} ${disabled ? classes.disabledText : ''}`}
          variant='caption'
          component='p'
        >
          {branch?.address}
        </Typography>
        {distanceAway && (
          <div className={classes.distance}>
            <Typography
              variant='caption'
              component='p'
              classes={{ caption: `${classes.distanceText} ${disabled ? classes.disabledText : ''}` }}
            >
              {distanceAway}
            </Typography>
          </div>
        )}
        <Typography variant='caption' classes={{ caption: `${classes.availability}` }} component='p'>
          {branchAvailability}
        </Typography>
      </Card>
    </div>
  )
}
