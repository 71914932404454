import { ccRegexPattern, sinRegexPattern, Branch, TimeSlot, i18n, copy } from '@atbdigitalteam/obs-shared-components'

const { invalid } = copy.Validation
const SENSITIVE_INFO = i18n.__(copy.Validation.sensitiveInfo)

export const NAME_REGEX = /^[A-Za-zÀ-ÖØ-öø-ÿ0-9]+(([' -][A-Za-zÀ-ÖØ-öø-ÿ0-9 ])?[A-Za-zÀ-ÖØ-öø-ÿ0-9]*)*$/
export const EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
export const PHONE_NUMBER_REGEX = /^\(\d{3}\)\s\d{3}-\d{4}$/

export const required = (value: string | Branch | Date | TimeSlot | null, message?: string) =>
  value ? undefined : message || 'Required field'

export const emailFormat = (value: string) => (EMAIL_REGEX.exec(value) === null ? i18n.__(invalid.email) : undefined)

export const nameFormat = (value: string) => (NAME_REGEX.exec(value) === null ? i18n.__(invalid.name) : undefined)

export const phoneFormat = (value: string) =>
  PHONE_NUMBER_REGEX.exec(value) === null ? i18n.__(invalid.phoneNumber) : undefined

export const phoneAreaCode = (value: string) => {
  const numbersOnly = value.replace(/\D/g, '')
  const invalidPrefixes = ['0', '1', '555']

  return invalidPrefixes.some(n => numbersOnly.startsWith(n)) ? i18n.__(invalid.areaCode) : undefined
}

export const sensitiveInfo = (value: string) =>
  sinRegexPattern.exec(value) !== null || ccRegexPattern.exec(value) !== null ? SENSITIVE_INFO : undefined
