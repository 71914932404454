import { TimeSlot } from '@atbdigitalteam/obs-shared-components'
import { action, observable } from 'mobx'
import { createContext, useContext } from 'react'

export class CalendarStore {
  @observable availableSlots?: TimeSlot[]
  @observable availableDates?: string[]
  @observable isFetching = false
  @observable isFetchingDates?: Promise<void>
  @observable isFetchingSlots?: Promise<void>
  @observable isReleasingSlot?: Promise<void>

  @action
  setAvailableSlots = (availableSlots?: TimeSlot[]): void => {
    this.availableSlots = availableSlots
  }

  @action
  setAvailableDates = (availableDates?: string[]): void => {
    this.availableDates = availableDates
  }

  @action
  setIsFetching = (isFetching: boolean): void => {
    this.isFetching = isFetching
  }

  @action
  setIsFetchingDates = () => {
    this.isFetching = true
    let ready: () => void
    this.isFetchingDates = new Promise(resolve => (ready = resolve))
    this.isFetchingDates.then(() => {
      this.isFetchingSlots ? this.isFetchingSlots.then(() => (this.isFetching = false)) : (this.isFetching = false)
    })
    return ready!
  }

  @action
  setIsFetchingSlots = () => {
    this.isFetching = true
    let ready: () => void
    this.isFetchingSlots = new Promise(resolve => (ready = resolve))
    this.isFetchingSlots.then(() => {
      this.isFetchingDates ? this.isFetchingDates.then(() => (this.isFetching = false)) : (this.isFetching = false)
    })
    return ready!
  }

  @action
  setIsReleasingSlot = () => {
    let ready: () => void
    this.isReleasingSlot = new Promise(resolve => (ready = resolve))
    return ready!
  }

  @action
  clearStore = (): void => {
    this.availableSlots = undefined
    this.availableDates = undefined
    this.isFetching = false
  }
}

export const calendarStore = new CalendarStore()

export const CalendarStoreContext = createContext<CalendarStore>(calendarStore)

export const useCalendarStore = () => useContext(CalendarStoreContext)
