import { ActionButton, copy, i18n } from '@atbdigitalteam/obs-shared-components'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'

import { Modal } from './Modal/Modal'
import { expiredModalStyle } from './Modal/ModalStyles'
import { StringReplace } from '../../translations'

export interface LogoutProps {
  loggedIn?: boolean
  testid?: string
  text: string
  onClick: () => void
}

const useStyles = makeStyles({
  button: {
    width: '100px'
  }
})

export const Logout = ({ loggedIn = false, testid = 'logout-button', text, onClick }: LogoutProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const classes = useStyles()

  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Modal
        open={open}
        displayCloseButton={false}
        modalStyle={expiredModalStyle}
        modalTitle={i18n.__(copy.Auth.LogoutModal.title)}
        modalBody={StringReplace(i18n.__(copy.Auth.LogoutModal.text), {
          '%LOGOUT_CANCEL%': loggedIn ? 'logout' : 'cancel'
        })}
        onClose={handleClose}
        modalFooter={
          <>
            <ActionButton
              className={classes.button}
              buttonText={i18n.__(copy.Buttons.Yes)}
              type='button'
              data-testid={`${testid}-yes`}
              onClick={onClick}
            />
            <ActionButton
              className={classes.button}
              buttonText={i18n.__(copy.Buttons.No)}
              type='button'
              data-testid={`${testid}-no`}
              onClick={handleClose}
            />
          </>
        }
      />
      <ActionButton testid={testid} buttonText={text} type='button' variant='outlined' onClick={handleClick} />
    </>
  )
}
