import { AppointmentSubTypeEnum, Event, MeetingMethodEnum, TimeSlot } from '@atbdigitalteam/obs-shared-components'
import moment from 'moment-timezone'
import { ALBERTA_TIMEZONE } from '../../utils/constants'

import { getEventByBookingId } from '../../requests'
import { bookingStore } from '../stores'

export const fetchEventByBookingId = async (bookingId: string, email: string) => {
  const response = await getEventByBookingId(bookingId, email)

  if (response.status === 201) {
    const event = response.data as Event
    const startDateTime = moment(event.startDateTime).tz(ALBERTA_TIMEZONE)
    const timeslot = {
      time: startDateTime.format('h:mm'),
      type: startDateTime.format('a')
    } as TimeSlot

    const eventDate = new Date(event.startDateTime)
    bookingStore.setAdvisor(event.advisor)
    bookingStore.setMeetingMethod(event.meetingMethod || MeetingMethodEnum.BRANCH)
    bookingStore.setAppointmentType(event.appointmentType)
    bookingStore.setBranch(event.branch)
    bookingStore.setBusinessName(event.businessName)
    bookingStore.setEventId(event.eventId)
    bookingStore.setDescription(event.description)
    bookingStore.setFirstName(event.customer.firstName)
    bookingStore.setLastName(event.customer.lastName)
    bookingStore.setEmail(event.customer.email)
    bookingStore.setEmployerName(event.customer.employerName)
    bookingStore.setProvince(event.customer.province)
    bookingStore.setPhoneNumber(event.customer.phoneNumber)
    bookingStore.setExistingCustomer(event.existingCustomer)
    bookingStore.setCaslConsent(event.casl?.consent)
    bookingStore.setDate(eventDate)
    bookingStore.setServiceSubType(
      Object.values(AppointmentSubTypeEnum).includes(event.serviceSubType) ? event.serviceSubType : undefined
    )
    bookingStore.setTimeSlot(timeslot)
    bookingStore.setModifyingEvent({
      appointmentType: event.appointmentType,
      branch: event.branch,
      eventId: event.eventId,
      description: event.description,
      firstName: event.customer.firstName,
      date: eventDate,
      serviceSubType: event.serviceSubType,
      timeSlot: timeslot,
      meetingMethod: event.meetingMethod || MeetingMethodEnum.BRANCH
    })
    bookingStore.setPreferredContactMethod(event.preferredContactMethod)
    bookingStore.setBPID(event.bpId)
    bookingStore.setReminderRequested(event.reminderRequested)
    bookingStore.setOrigin(event.origin)
    bookingStore.setCaseId(event.caseId)
    bookingStore.setLoanAmount(event.loanAmount)
  }
}
