import Button from '@material-ui/core/Button'
import React from 'react'

export interface UINotificationActionProps {
  onClose: () => void
}

export const UINotificationAction = ({ onClose }: UINotificationActionProps) => (
  <Button color='inherit' onClick={onClose}>
    Dismiss
  </Button>
)
