import axios, { CancelToken } from 'axios'
import { ApiRequest } from './ApiRequest'

const API_SERVER = process.env.API_SERVER || ''
const BASE_PATH = `${API_SERVER}/api/customers`

export interface Customer {
  customerName: {
    firstName?: string | undefined
    lastName?: string | undefined
  }
  email?: string | undefined
  phoneNumber?: string | undefined
  bpId?: string | undefined
}

export const getCustomerInfo = async (token: string, cancelToken: CancelToken): Promise<Customer> => {
  const url = BASE_PATH
  return new ApiRequest()
    .get(url)
    .withHeaders({ Authorization: `Bearer ${token}` })
    .withCancelToken(cancelToken)
    .send()
    .then(response => response.data)
    .catch(error => {
      if (axios.isCancel(error)) {
        // Supress error thrown by axios if request was cancelled
      }
      return {
        customerName: {}
      }
    })
}
