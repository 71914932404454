import { Advisor, AppointmentOriginEnum, MeetingMethodEnum, ProvinceEnum } from '@atbdigitalteam/obs-shared-components'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import AccountCircle from '@material-ui/icons/AccountCircle'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import DateRangeIcon from '@material-ui/icons/DateRange'
import NotesIcon from '@material-ui/icons/Notes'
import PersonIcon from '@material-ui/icons/Person'
import RoomIcon from '@material-ui/icons/Room'
import ScheduleIcon from '@material-ui/icons/Schedule'
import React, { ReactElement } from 'react'
import { getShortBookingId } from '../../../utils/bookingId'
import { OBSLiteOrigins } from '../../../utils/constants'
import { AddToCalendar, Event } from '../AddToCalendar'
import { MeetingComponents } from '../../../utils/meetingMethod'

const useStyles = makeStyles({
  editButton: {
    minWidth: '0',
    padding: '0',
    float: 'right',
    '&:hover': {
      background: 'inherit'
    },
    '&:focus': {
      outline: '-webkit-focus-ring-color solid 1px',
      outlineOffset: '3px'
    }
  },
  editButtonLabel: {
    fontWeight: 600,
    color: '#0072F0',
    textDecoration: 'none'
  },
  branchAddress: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  label: {
    fontWeight: 600,
    marginBottom: '5px',
    fontFamily: 'inherit',
    fontSize: '0.875rem'
  },
  inlineLink: {
    textDecoration: 'underline'
  },
  icon: {
    width: '1rem',
    height: '1rem'
  },
  indent: ({ enhanced }: AppointmentSummaryProps) => ({
    marginLeft: enhanced ? '1rem' : undefined
  })
})

export interface AppointmentSummaryProps {
  branchName: string
  branchAddress: string
  serviceSummary: string
  editLabelText?: string
  onClickEdit?: (event: React.MouseEvent) => void
  date: string
  timeRange: string
  advisor?: Advisor
  meetingMethod?: MeetingMethodEnum
  enhanced?: boolean
  meetingLink?: string
  addToCalendarEvent?: Event
  bookingId?: string
  origin?: AppointmentOriginEnum
  province?: ProvinceEnum
  existingGWS?: boolean
  employerName?: string
}

export const AppointmentSummary = (props: AppointmentSummaryProps): ReactElement => {
  const classes = useStyles(props)
  const {
    branchName,
    branchAddress,
    serviceSummary,
    date,
    timeRange,
    advisor,
    editLabelText,
    onClickEdit,
    meetingMethod,
    enhanced,
    meetingLink,
    addToCalendarEvent,
    bookingId,
    origin,
    province,
    existingGWS,
    employerName
  } = props

  const { icon: meetingIcon, text: meetingText } = MeetingComponents(classes.icon, meetingMethod)

  return (
    <Grid container direction='column' wrap='nowrap' spacing={1} data-testid='appointment-summary'>
      <Grid item>
        <Grid container>
          <Grid item xs>
            <Typography className={classes.label} variant='h2'>
              Appointment information
            </Typography>
          </Grid>
          <Grid item xs>
            {onClickEdit && (
              <Button disableRipple onClick={onClickEdit} className={classes.editButton} data-testid='edit-link'>
                <Typography className={classes.editButtonLabel} variant='body2'>
                  {editLabelText || 'Edit'}
                </Typography>
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      {advisor && OBSLiteOrigins.includes(origin) && (
        <Grid item>
          <Grid container spacing={2} className={classes.indent}>
            <Grid item>
              <PersonIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              <Typography variant='body2' data-testid='appointment-summary-advisor'>
                {`Meeting with ${advisor.name || 'ATB team member'}`}
                {enhanced && advisor.email && (
                  <>
                    {' ('}
                    <Link href={`mailto:${advisor.email}`} className={classes.inlineLink}>
                      {advisor.email}
                    </Link>
                    {')'}
                  </>
                )}
                {`, ${branchName}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {meetingMethod && (
        <Grid item>
          <Grid container spacing={2} className={classes.indent}>
            <Grid item>{meetingIcon}</Grid>
            <Grid item xs>
              <Grid item>
                <Typography variant='body2' data-testid='appointment-summary-meeting-method-text'>
                  {meetingText}
                </Typography>
              </Grid>
              {meetingMethod === MeetingMethodEnum.ONLINE && meetingLink && (
                <Grid item>
                  <Typography variant='body2' data-testid='appointment-summary-meeting-link'>
                    Join with Google Meet:{' '}
                    <Link href={meetingLink} className={classes.inlineLink}>
                      {meetingLink.replace('https://', '')}
                    </Link>
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item>
        <Grid container spacing={2} wrap='nowrap' className={classes.indent}>
          <Grid item>
            <DateRangeIcon className={classes.icon} />
          </Grid>
          <Grid item xs>
            <Typography variant='body2' data-testid='appointment-summary-date'>
              {date}
            </Typography>
            <AddToCalendar event={addToCalendarEvent} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={2} className={classes.indent}>
          <Grid item>
            <ScheduleIcon className={classes.icon} />
          </Grid>
          <Grid item xs>
            <Typography variant='body2' data-testid='appointment-summary-time'>
              {timeRange}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {origin !== AppointmentOriginEnum.GWS && (
        <Grid item>
          <Grid container spacing={2} className={classes.indent}>
            <Grid item>
              <NotesIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              <Typography variant='body2' data-testid='appointment-summary-service-type'>
                {serviceSummary}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {origin === AppointmentOriginEnum.GWS && (
        <Grid item>
          <Grid container spacing={2} className={classes.indent}>
            <Grid item>
              <RoomIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              <Grid item>
                <Typography variant='body2' data-testid='appointment-summary-province'>
                  Province of Residence: {province}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={2} className={classes.indent}>
              <Grid item>
                <AccountCircle className={classes.icon} />
              </Grid>
              <Grid item xs>
                <Grid item>
                  <Typography variant='body2' data-testid='appointment-summary-existing-GWS'>
                    Existing ATB Wealth or ATB Private Investment Account: {existingGWS ? 'Yes' : 'No'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {employerName && origin === AppointmentOriginEnum.GWS && (
        <Grid item>
          <Grid container spacing={2} className={classes.indent}>
            <Grid item>
              <NotesIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              <Grid item>
                <Typography noWrap variant='body2' data-testid='appointment-summary-employer-name'>
                  Company Name: {employerName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {advisor && !OBSLiteOrigins.includes(origin) && (
        <Grid item>
          <Grid container spacing={2} className={classes.indent}>
            <Grid item>
              <PersonIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              <Typography variant='body2' data-testid='appointment-summary-advisor'>
                {`Meeting with ${advisor.name || 'ATB team member'}`}
                {enhanced && advisor.email && (
                  <>
                    {' ('}
                    <Link href={`mailto:${advisor.email}`} className={classes.inlineLink}>
                      {advisor.email}
                    </Link>
                    {')'}
                  </>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {origin === AppointmentOriginEnum.EFS && (
        <Grid item>
          <Grid container spacing={2} className={classes.indent}>
            <Grid item>
              <RoomIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              <Grid item>
                <Typography noWrap variant='body2' data-testid='appointment-summary-branch-name'>
                  {branchName}
                </Typography>
              </Grid>
              {meetingMethod === MeetingMethodEnum.BRANCH && (
                <Grid item>
                  <Typography
                    variant='caption'
                    className={classes.branchAddress}
                    data-testid='appointment-summary-branch-address'
                  >
                    {branchAddress}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
      {bookingId && (
        <Grid item>
          <Grid container spacing={2} className={classes.indent}>
            <Grid item>
              <AssignmentIndIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              <Grid item>
                <Typography noWrap variant='body2' data-testid='appointment-summary-booking-id'>
                  Booking ID: {getShortBookingId(bookingId)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
