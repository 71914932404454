import { ApiRequest } from './ApiRequest'

const API_SERVER = process.env.API_SERVER || ''
const BASE_PATH = `${API_SERVER}/api/events`

export const getEventByBookingId = (bookingId: string, customerEmail: string) => {
  const url = `${BASE_PATH}/validate`
  const body = { bookingId, customerEmail }

  return new ApiRequest().post(url).withData(body).send()
}

export const deleteEventByBookingId = (
  eventId: string,
  bookingId: string,
  customerEmail: string,
  sendEmail: boolean
) => {
  const url = `${BASE_PATH}/${eventId}`
  const body = { bookingId, customerEmail, sendEmail }

  return new ApiRequest().delete(url).withData(body).send()
}
