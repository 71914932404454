import { SvgIconProps } from '@material-ui/core/SvgIcon'
import Typography from '@material-ui/core/Typography'
import React, { ComponentType, ReactElement } from 'react'

export interface UINotificationMessageProps {
  Icon: ComponentType<SvgIconProps>
  message: ReactElement | string
}

export const UINotificationMessage = ({ Icon, message }: UINotificationMessageProps) => (
  <span className='uik-notification-message'>
    <Icon className='mr-s1' />
    <Typography variant='body1'>{message}</Typography>
  </span>
)
