import React from 'react'
import reactStringReplace from 'react-string-replace'

interface StringReplacements {
  [key: string]: string
}

export const StringReplace = (str: string, replacements: StringReplacements): string => {
  return str.replace(/%\w+%/g, item => {
    return replacements[item] || item
  })
}

export const BoldSubString = (str: string, key: string) =>
  reactStringReplace(str, /(<b>.*?<\/b>)/g, (match: string, index: number) =>
    React.createElement('b', { key: `${key}-${index}` }, match.replace(/<b>|<\/b>/g, ''))
  )
