import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Info from '@material-ui/icons/Info'
import React from 'react'

const useStyles = makeStyles({
  root: {
    background: 'white',
    borderLeft: '6px solid #0072F0',
    maxWidth: '670px',
    width: '100%',
    paddingTop: '16px',
    paddingBottom: '16px',
    paddingRight: '16px',
    boxShadow: '1px 1px 4px 0 rgba(190, 190, 190, 0.5)',
    margin: 'auto'
  },
  infoIcon: {
    color: '#0072F0',
    marginRight: '12px',
    marginLeft: '12px'
  }
})

export interface StatusNotificationProps {
  title: string
  message: string
}

export const StatusNotification = ({ title, message }: StatusNotificationProps) => {
  const classes = useStyles()
  return (
    <Grid container className={classes.root} data-testid='alert-banner'>
      <Grid item>
        <Info className={classes.infoIcon} fontSize='small' />
      </Grid>
      <Grid item xs>
        <Typography variant='body2'>
          <strong>{title}</strong>
        </Typography>
        <Typography variant='body2'>{message}</Typography>
      </Grid>
    </Grid>
  )
}
