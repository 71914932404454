import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import React, { ReactElement } from 'react'
import { PageTitle } from './PageTitle'
import { DialogueDisplay } from '@atbdigitalteam/obs-shared-components'

export interface OBSLiteErrorProps {
  bigTitle: string
  littleTitle: string
  insideText: string
  dialogueFooter: ReactElement
}

const useStyles = makeStyles({
  formRoot: {
    width: '100%',
    maxWidth: '482px',
    paddingRight: '8px',
    paddingLeft: '8px',
    marginBottom: '8px'
  },
  formItemNoMargin: {
    width: '100%'
  },
  formItem: {
    width: '448px',
    marginBottom: '8px'
  },
  labelText: {
    fontSize: '14px',
    marginBottom: '8px',
    width: '448px'
  }
})

export const OBSLiteError = ({ bigTitle, littleTitle, insideText, dialogueFooter }: OBSLiteErrorProps) => {
  const classes = useStyles()

  return (
    <Grid container justifyContent='center' alignItems='center' direction='column' data-testid='OBSLiteError'>
      <Grid container direction='column' spacing={2} className={classes.formRoot}>
        <Grid item className={classes.formItemNoMargin}>
          <PageTitle littleTitle={littleTitle} bigTitle={bigTitle} />
        </Grid>
      </Grid>
      <Grid item className={classes.formItem} data-testid='OBSLiteError-message'>
        <DialogueDisplay id='inner-error' displayMessage={insideText} dialogueFooter={dialogueFooter} />
      </Grid>
    </Grid>
  )
}
