import type { Features, OutageAlert } from '@atbdigitalteam/obs-shared-components'
import { action, observable } from 'mobx'
import { createContext, useContext } from 'react'

const defaultOutageAlert = { show: false, title: '', message: '' }

export class FeatureStore {
  @observable disableAuthRedirect = false
  @observable featuresLoaded = false
  @observable skipFindALocation = false
  @observable obsOutageAlert: OutageAlert = defaultOutageAlert
  @observable branchAlertNotification: OutageAlert = defaultOutageAlert
  @observable usePingBusinessLoginFlow = false

  @action
  initFeatureStore = (features: Features): void => {
    this.disableAuthRedirect = features.disableAuthRedirect || false
    this.featuresLoaded = true
    this.obsOutageAlert = features.obsOutageAlert || defaultOutageAlert
    this.branchAlertNotification = features.branchAlertNotification || defaultOutageAlert
    this.usePingBusinessLoginFlow = features.usePingBusinessLoginFlow || false
  }

  @action
  resetFeatureStore = (): void => {
    this.disableAuthRedirect = false
    this.featuresLoaded = false
    this.skipFindALocation = false
    this.obsOutageAlert = defaultOutageAlert
    this.branchAlertNotification = defaultOutageAlert
    this.usePingBusinessLoginFlow = false
  }

  @action
  setSkipFindALocation = (skip: boolean): void => {
    this.skipFindALocation = skip
  }
}

export const featureStore = new FeatureStore()

export const FeatureStoreContext = createContext<FeatureStore>(featureStore)

export const useFeatureStore = () => useContext<FeatureStore>(FeatureStoreContext)
