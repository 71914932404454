import { copy, i18n } from '@atbdigitalteam/obs-shared-components'
import Typography from '@material-ui/core/Typography'
import React from 'react'

import { StringReplace } from '../../translations'

interface TeamLabelProps {
  teamName: string
  advisorName: string
  className?: string
}

export const AdvisorAndTeamLabel = ({ advisorName, teamName, className }: TeamLabelProps) => {
  return (
    <Typography className={className} variant='body1' data-testid='team-label'>
      <b>
        {StringReplace(i18n.__(copy.ObsLite.TeamLabel), {
          '%ADVISOR%': advisorName,
          '%TEAM%': teamName
        })}
      </b>
    </Typography>
  )
}
