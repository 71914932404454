import moment from 'moment-timezone'
import { ALBERTA_TIMEZONE } from '../../utils/constants'

import { getAvailability, getAvailableDates, releaseTimeslot as releaseTimeslotRequest } from '../../requests'
import { isEqual } from '../../utils/comparison'
import { bookingStore, calendarStore } from '../stores'

export const fetchAvailability = async (): Promise<void> => {
  calendarStore.setAvailableSlots(undefined)

  if (
    bookingStore.branch &&
    bookingStore.meetingMethod &&
    bookingStore.appointmentType &&
    bookingStore.serviceSubType &&
    bookingStore.date &&
    (bookingStore.proficiency || bookingStore.proficiency === 0)
  ) {
    await Promise.all([calendarStore.isReleasingSlot, calendarStore.isFetchingDates])
    const ready = calendarStore.setIsFetchingSlots()

    await getAvailability(
      bookingStore.branch.transit,
      bookingStore.date,
      bookingStore.appointmentType,
      bookingStore.serviceSubType,
      bookingStore.proficiency,
      bookingStore.meetingMethod,
      bookingStore.advisor,
      bookingStore.bookingId,
      bookingStore.loanAmount,
      bookingStore.origin
    )
      .then(timeslots => {
        calendarStore.setAvailableSlots(timeslots)

        // if selected timeslot no longer availabe, unselect it
        if (bookingStore.timeSlot && !timeslots.find(item => isEqual(item, bookingStore.timeSlot))) {
          bookingStore.setTimeSlot(undefined)
        }
      })
      .catch(() => {
        calendarStore.setAvailableSlots([])
        bookingStore.setTimeSlot(undefined)
      })
      .finally(() => ready())
  }
}

export const fetchAvailableDates = async (): Promise<boolean | void> => {
  let selectedDateAvailable = false
  calendarStore.setAvailableDates(undefined)

  if (
    bookingStore.branch &&
    bookingStore.meetingMethod &&
    bookingStore.appointmentType &&
    bookingStore.serviceSubType &&
    (bookingStore.proficiency || bookingStore.proficiency === 0)
  ) {
    await calendarStore.isReleasingSlot
    const ready = calendarStore.setIsFetchingDates()
    calendarStore.setAvailableSlots([])

    const endDate = moment.tz(ALBERTA_TIMEZONE).add(2, 'months').toDate()

    await getAvailableDates(
      bookingStore.branch.transit,
      bookingStore.minDate,
      endDate,
      bookingStore.appointmentType,
      bookingStore.serviceSubType,
      bookingStore.proficiency,
      bookingStore.meetingMethod,
      bookingStore.advisor,
      bookingStore.bookingId,
      bookingStore.loanAmount,
      bookingStore.origin
    )
      .then(dates => {
        if (bookingStore.date && dates.includes(moment.tz(bookingStore.date, ALBERTA_TIMEZONE).format('YYYY-MM-DD'))) {
          selectedDateAvailable = true
        }

        calendarStore.setAvailableDates(dates)
      })
      .catch(() => {
        calendarStore.setAvailableDates([])
        bookingStore.setDate(bookingStore.minDate)
      })
      .finally(() => ready())
  }

  return selectedDateAvailable
}

export const releaseTimeslot = async (keepEventId = false): Promise<void> => {
  const { eventId, transit, bookingId } = bookingStore
  const ready = calendarStore.setIsReleasingSlot()

  if (bookingId && eventId && transit) {
    try {
      await releaseTimeslotRequest(transit, eventId, bookingId!)

      if (!keepEventId) {
        bookingStore.setEventId(undefined)
        bookingStore.setBookingId(undefined)
      }
    } catch {
      // noop
    }

    ready()
  }
}
