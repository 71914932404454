import { AppointmentOriginEnum } from '@atbdigitalteam/obs-shared-components'

export const ALBERTA_TIMEZONE = 'America/Edmonton'
export const FORM_WIDTH = '480px'

export const OBSLiteOrigins: (AppointmentOriginEnum | undefined)[] = [
  AppointmentOriginEnum.ACQUISITION,
  AppointmentOriginEnum.MORTGAGE_RENEWAL
]

export const WealthOrigins: (AppointmentOriginEnum | undefined)[] = [
  AppointmentOriginEnum.GWS,
  AppointmentOriginEnum.PROSPER
]
