import { Advisor } from '@atbdigitalteam/obs-shared-components'

export const sortAdvisorList = (advisors: Advisor[]): Advisor[] => {
  if (advisors.length > 0) {
    advisors.sort((a, b) => {
      const nameA = a.name.toUpperCase()
      const nameB = b.name.toUpperCase()
      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0
    })
  }

  return advisors
}
