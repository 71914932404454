import { createStyles, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      margin: 0
    }
  })
)

interface GoogleRecaptchaProps {
  siteKey: string
  onChangeRecaptcha: (token: string | null) => void
  onRecaptchaExpire: () => void
}

export const GoogleRecaptcha = ({ siteKey, onChangeRecaptcha, onRecaptchaExpire }: GoogleRecaptchaProps) => {
  const classes = useStyles()

  return (
    <Grid data-testid='google-recaptcha' container className={classes.root} justifyContent='center'>
      <Grid item>
        <ReCAPTCHA sitekey={siteKey} onChange={onChangeRecaptcha} onExpired={onRecaptchaExpire} />
      </Grid>
    </Grid>
  )
}
