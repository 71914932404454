import { Branch, FormErrors, ValidationError } from '@atbdigitalteam/obs-shared-components'
import { Field, FieldProps } from 'formik'
import React, { useRef } from 'react'

import { BranchList } from './BranchList'

export interface BranchListFieldProps {
  name: string
  options: Branch[]
  onChange?: (newValue: Branch | null) => void
  onShowMore?: () => Promise<void>
  validate?: (value: Branch) => string | undefined
  validateOnBlur?: boolean
}

export const BranchListField = ({
  name,
  options,
  onChange = () => {
    // noop
  },
  onShowMore,
  validate,
  validateOnBlur
}: BranchListFieldProps) => {
  const fieldRef = useRef<HTMLDivElement | null>(null)

  return (
    <Field name={name} validate={validate}>
      {({ field, form, meta }: FieldProps) => (
        <>
          <BranchList
            ref={fieldRef}
            options={options}
            value={field.value}
            onChange={(value: Branch | null) => {
              form.setFieldValue(field.name, value)
              onChange(value)
            }}
            onBlur={() => {
              if (validateOnBlur) {
                form.setFieldTouched(field.name)
              }
            }}
            onShowMore={onShowMore}
          />
          <ValidationError
            name={field.name}
            errMsg={meta.error}
            touched={meta.touched}
            element={fieldRef}
            formErrors={form.errors as FormErrors}
          />
        </>
      )}
    </Field>
  )
}
