import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const expiredModalStyle = makeStyles((theme: Theme) =>
  createStyles({
    // Title CSS
    heading: {
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: '20px',
      top: theme.spacing(1)
    },
    // Divider CSS
    orangeDivider: {
      border: '2px solid #FF7F30',
      width: '40px',
      height: '1px',
      margin: '0px 24px',
      backgroundColor: '#FF7F30'
    },
    // Body CSS
    body: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(10),
      fontSize: '14px',
      lineHeight: '18px',
      color: '#63666B',
      whiteSpace: 'pre-wrap'
    },
    // Footer CSS
    confirmButton: {
      marginTop: theme.spacing(2),
      position: 'absolute',
      right: theme.spacing(1),
      bottom: theme.spacing(3)
    },
    closeButton: {
      position: 'absolute',
      marginTop: 0,
      right: theme.spacing(1),
      top: theme.spacing(0.5),
      color: 'black'
    }
  })
)
