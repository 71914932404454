import { Features } from '@atbdigitalteam/obs-shared-components'
import { ApiRequest } from './ApiRequest'

const API_SERVER = process.env.API_SERVER || ''
const BASE_PATH = `${API_SERVER}/api`

export const getFeatures = (): Promise<Features> => {
  const url = `${BASE_PATH}/features`
  return new ApiRequest()
    .get(url)
    .send()
    .then(response => {
      return response.data
    })
}
