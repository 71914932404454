import { Auth0Provider } from '@auth0/auth0-react'
import React from 'react'

import { routes } from '../../routing'

export const Auth0Wrapper = ({ children }: { children: React.ReactNode }) => {
  return process.env.REACT_APP_SKIPAUTH === 'true' ? (
    <>{children}</>
  ) : (
    <Auth0Provider
      audience='https://api.atb.com/'
      domain={process.env.AUTH0_DOMAIN!}
      clientId={process.env.AUTH0_CLIENT_ID!}
      redirectUri={`${window.location.origin}/book`}
      onRedirectCallback={appState => {
        const buildUrl = () => {
          if (appState?.params) {
            return `${window.location.origin}${routes.details.path}${appState.params}`
          }
          return `${window.location.origin}/book`
        }

        history.replaceState({}, document.title, buildUrl())
      }}
    >
      {children}
    </Auth0Provider>
  )
}
