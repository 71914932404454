import {
  ActionButton,
  AppointmentTypeField,
  MeetingMethodField,
  MeetingMethodEnum,
  DatePickerField,
  TimeSlotPickerField,
  TimeSlot,
  StringReplace,
  minutesToUserFacingTimeString,
  TextInputField,
  ServiceTypeSelectField,
  AppointmentSubTypeEnum,
  DialogueDisplay,
  AppointmentTypeEnum,
  AppointmentOriginEnum,
  i18n,
  copy,
  Options
} from '@atbdigitalteam/obs-shared-components'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Form, Formik, FormikHelpers } from 'formik'
import React from 'react'
import { observer } from 'mobx-react'

import { PageTitle } from '../PageTitle'
import { AdvisorAndTeamLabel } from '../AdvisorAndTeamLabel'
import { useBookingStore, useCalendarStore } from '../../../injectables'
import { DetailsFormValues } from '../../../types'
import { required, sensitiveInfo } from '../../../utils/fieldValidation'

export interface ObsLiteFormProps {
  bigTitle: string
  littleTitle: string
  submitButtonText: string
  handleSubmit: (values: DetailsFormValues, actions: FormikHelpers<DetailsFormValues>) => void
}

const useStyles = makeStyles({
  formRoot: {
    width: '100%',
    maxWidth: '482px',
    paddingRight: '8px',
    paddingLeft: '8px',
    marginBottom: '80px'
  },
  formItem: {
    width: '100%',
    marginBottom: '8px'
  },
  formItemNoMargin: {
    width: '100%'
  },
  labelText: {
    fontSize: '14px',
    marginBottom: '8px'
  },
  textBox: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    borderRadius: 4,
    backgroundColor: 'rgb(0, 114, 240)',
    marginLeft: '8px',
    marginRight: '8px',
    marginBottom: '8px',
    maxWidth: '450px'
  },
  text: {
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#63666b',
    margin: '8px'
  },
  inlineLink: {
    textDecoration: 'underline'
  }
})

export const BaseObsLiteForm = ({ bigTitle, littleTitle, submitButtonText, handleSubmit }: ObsLiteFormProps) => {
  const classes = useStyles()

  const { availableSlots, availableDates, isFetching } = useCalendarStore()

  const {
    advisor,
    appointmentType,
    appointmentLength,
    branch,
    date,
    description,
    minDate,
    meetingMethod,
    origin,
    serviceSubType,
    timeSlot,
    timezoneLabel,
    setAppointmentType,
    setDate,
    setDescription,
    setMeetingMethod,
    setServiceSubType,
    setTimeSlot
  } = useBookingStore()

  const requiredText = copy.Validation.required

  return (
    <Formik
      initialValues={
        {
          advisor,
          appointmentType,
          branch,
          date,
          description,
          meetingMethod,
          serviceSubType,
          timeSlot
        } as DetailsFormValues
      }
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isSubmitting, values }) => (
        <Form>
          <Grid data-testid='obs-lite-form' container justifyContent='center' alignItems='center' direction='column'>
            <Grid container direction='column' spacing={2} className={classes.formRoot}>
              <Grid item className={classes.formItemNoMargin}>
                <PageTitle littleTitle={littleTitle} bigTitle={bigTitle} />
              </Grid>

              {branch && branch.name && advisor && advisor.name && (
                <Grid item className={classes.formItemNoMargin}>
                  <AdvisorAndTeamLabel
                    advisorName={advisor!.name}
                    teamName={branch.name}
                    className={classes.labelText}
                  />
                </Grid>
              )}

              <Grid item className={classes.formItem}>
                <MeetingMethodField
                  name='meetingMethod'
                  isProsper
                  onClickOption={(value: string) => {
                    setMeetingMethod(value as MeetingMethodEnum)
                  }}
                  validate={value => required(value, i18n.__(requiredText.meetingMethod))}
                  validateOnBlur
                />
              </Grid>

              {origin === AppointmentOriginEnum.ACQUISITION && (
                <>
                  <Grid item className={classes.formItem}>
                    <AppointmentTypeField
                      name='appointmentType'
                      origin={origin}
                      onClickOption={(value: string) => {
                        if (appointmentType !== value) {
                          setAppointmentType(value as AppointmentTypeEnum)
                          setServiceSubType(undefined)
                          setTimeSlot(undefined)
                          setDate(null)
                        }
                      }}
                      validate={value => required(value, i18n.__(requiredText.appointmentType))}
                      validateOnBlur
                    />
                  </Grid>
                  <Grid item className={classes.formItem}>
                    <Typography
                      className={classes.labelText}
                      variant='body1'
                      data-testid='service-subtype-select-header'
                    >
                      What can we help you with?
                    </Typography>
                    <ServiceTypeSelectField
                      name='serviceSubType'
                      options={origin && appointmentType ? Options.ServiceSubTypeObsLite[origin][appointmentType] : []}
                      label='Select service type'
                      onChange={(event: React.ChangeEvent<{ value: AppointmentSubTypeEnum }>) => {
                        setServiceSubType(event.target.value)
                      }}
                      validate={value => required(value, i18n.__(requiredText.serviceSubType))}
                      validateOnBlur
                    />
                  </Grid>
                </>
              )}

              {availableDates?.length === 0 && (
                <Grid container direction='column' className={classes.textBox}>
                  <DialogueDisplay id='no-dates-notice' displayMessage={i18n.__(copy.Error.NoDates)} />
                </Grid>
              )}

              {(availableDates === undefined || availableDates.length > 0) && (
                <Grid item className={classes.formItem}>
                  <TextInputField
                    name='description'
                    label={i18n.__(copy.Book.DescriptionInput.Label)}
                    placeHolderText={i18n.__(copy.Book.DescriptionInput.PlaceHolderText)}
                    onChange={(value: string) => {
                      setDescription(value)
                    }}
                    validate={value => required(value, i18n.__(requiredText.description)) || sensitiveInfo(value)}
                    validateOnBlur
                    ariaRequired
                  />
                </Grid>
              )}

              {availableDates && availableDates.length > 0 && (
                <>
                  <Grid item className={classes.formItem}>
                    <DatePickerField
                      name='date'
                      minDate={minDate}
                      availableDates={availableDates!}
                      onChange={(value: Date | null) => {
                        setDate(value)
                      }}
                      validate={value => required(value, i18n.__(requiredText.date))}
                      validateOnBlur
                    />
                  </Grid>

                  {!isFetching && availableSlots && availableSlots.length > 0 && (
                    <Grid item className={classes.formItemNoMargin}>
                      <TimeSlotPickerField
                        name='timeSlot'
                        timeslots={availableSlots}
                        timezoneLabel={timezoneLabel}
                        onChange={(value: TimeSlot) => {
                          setTimeSlot(value)
                        }}
                        validate={value => required(value, i18n.__(requiredText.timeSlot))}
                        validateOnBlur
                      />
                    </Grid>
                  )}
                </>
              )}

              {isFetching && (
                <Grid item className={classes.formItem}>
                  <CircularProgress style={{ marginLeft: '45%' }} />
                </Grid>
              )}

              {values.branch && values.appointmentType && values.serviceSubType && (
                <Grid item className={classes.formItem}>
                  <Typography variant='body2' data-testid='appointment-length-notice'>
                    {StringReplace(i18n.__(copy.AppointmentLengthLabel[meetingMethod || MeetingMethodEnum.ONLINE]), {
                      '%LENGTH%': minutesToUserFacingTimeString(appointmentLength)
                    })}
                  </Typography>
                </Grid>
              )}

              <Grid item className={classes.formItem}>
                <ActionButton
                  style={{ marginTop: '16px' }}
                  data-testid='next-button'
                  buttonText={submitButtonText}
                  submitting={isSubmitting}
                  disabled={isSubmitting || !meetingMethod || !serviceSubType || !date || !timeSlot || !description}
                  type='submit'
                />
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export const ObsLiteForm = observer(BaseObsLiteForm)
