import { copy, i18n } from '@atbdigitalteam/obs-shared-components'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import SvgIcon from '@material-ui/core/SvgIcon'
import ToolBar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import React from 'react'

import chrome from '../../../resources/atb/webIcons/chrome.png'
import firefox from '../../../resources/atb/webIcons/firefox.png'
import microsoft_edge from '../../../resources/atb/webIcons/microsoft_edge.png'
import safari from '../../../resources/atb/webIcons/safari.png'

const useStyles = makeStyles(() =>
  createStyles({
    formRoot: {
      maxWidth: '45em',
      marginRight: '5em',
      marginLeft: '5em',
      marginTop: '2em',
      alignContent: 'center'
    },
    appBar: {
      background: '#0072F0'
    },
    toolbar: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridTemplateColumns: '1fr 1fr 1fr'
    },
    atbIcon: {
      backgroundImage:
        'url(https://grow-static-content.poweredbygrow.com/atb/rebrand/ATBPoweringPossibillity/ATB-PoweringPossibility_White_RGB.svg)',
      width: '177.21px',
      height: '44px',
      backgroundSize: '177.21px 44px'
    },
    logo: {
      justifySelf: 'center',
      alignSelf: 'center'
    },
    icon: {
      width: '90%',
      height: '90%',
      filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
      justifySelf: 'stretch',
      '&:hover': {
        transform: 'scale(1.1)'
      }
    },
    headerText: {
      font: 'inter',
      fontSize: '180%',
      fontWeight: 'normal',
      textAlign: 'center',
      lineHeight: '150%'
    },
    subText: {
      font: 'inter',
      fontSize: '100%',
      fontWeight: 'normal',
      textAlign: 'center',
      color: '#555555',
      lineHeight: '140%'
    },
    dividerBorder: {
      border: '1px solid #d3d3d3',
      light: 'true'
    }
  })
)
export const UnsupportedBrowserPage = () => {
  const classes = useStyles()

  return (
    <>
      <AppBar position='static' elevation={0} className={classes.appBar} data-testid='header'>
        <ToolBar className={classes.toolbar}>
          <div></div>
          <a aria-label='www.atb.com' data-testid='atb-logo' href='https://www.atb.com/' className={classes.logo}>
            <SvgIcon className={classes.atbIcon} />
          </a>
        </ToolBar>
      </AppBar>
      <Grid
        data-testid='unsupported-browser-page'
        container
        justifyContent='center'
        alignItems='center'
        direction='row'
      >
        <Grid container spacing={5} alignItems='center' className={classes.formRoot}>
          <Grid item>
            <Typography className={classes.headerText}>{i18n.__(copy.UnsupportedBrowser.BigTitle)}</Typography>
            <br />
            <Typography className={classes.subText}>{i18n.__(copy.UnsupportedBrowser.LittleTitle)}</Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Link href='https://www.google.com/intl/en_ca/chrome/' data-testid='chrome-browser-installation'>
              <img src={chrome} alt='chrome browser' className={classes.icon} />
            </Link>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Link href='https://www.microsoft.com/en-us/edge'>
              <img src={microsoft_edge} alt='microsoft edge browser' className={classes.icon} />
            </Link>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Link href='https://support.apple.com/downloads/safari'>
              <img src={safari} alt='safari browser' className={classes.icon} />
            </Link>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Link href='https://www.mozilla.org/en-CA/firefox/new/'>
              <img src={firefox} alt='firefox browser' className={classes.icon} />
            </Link>
          </Grid>
          <Grid item>
            <Divider variant='fullWidth' className={classes.dividerBorder} style={{ marginBottom: '2em' }} />
            <Typography className={classes.subText}>{i18n.__(copy.UnsupportedBrowser.MoreAssistance)}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
