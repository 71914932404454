import {
  calcProficiency,
  getAppointmentDuration,
  getMeetingMethodCategory,
  type Advisor,
  AppointmentOriginEnum,
  AppointmentSubTypeEnum,
  AppointmentTypeEnum,
  Branch,
  LoanAmountEnum,
  MeetingMethodEnum,
  type ModifyingEventAttributes,
  ProficiencyEnum,
  ProvinceEnum,
  type TimeSlot,
  i18n,
  copy
} from '@atbdigitalteam/obs-shared-components'
import { format } from 'date-fns'
import { action, computed, observable } from 'mobx'
import moment from 'moment-timezone'
import { createContext, useContext } from 'react'
import type { LatLongObject } from '../../modules/components/LocationSearch'
import { computeTimeSlotRange } from '../../utils/computeTimeSlotRange'
import { ALBERTA_TIMEZONE } from '../../utils/constants'

export interface PreFilledFields {
  firstName?: boolean
  lastName?: boolean
  email?: boolean
  phoneNumber?: boolean
  existingCustomer?: boolean
  description?: boolean
}

interface PreFillFieldsInput {
  firstName?: string
  lastName?: string
  email?: string
  phoneNumber?: string
  existingCustomer?: boolean
  bpId?: string
  description?: string
}

export class BookingStore {
  preFilledFields: PreFilledFields = {}
  defaults = {
    date: new Date(0) // default to start of epoch (Jan 1, 1970 UTC)
  }

  @observable advisor?: Advisor
  @observable meetingMethod?: MeetingMethodEnum
  @observable appointmentType?: AppointmentTypeEnum
  @observable branch: Branch | null = null
  @observable bookingId?: string
  @observable date: Date = this.defaults.date
  @observable description?: string
  @observable eventId?: string
  @observable serviceSubType?: AppointmentSubTypeEnum
  @observable timeSlot?: TimeSlot
  @observable businessName?: string
  @observable firstName?: string
  @observable lastName?: string
  @observable email?: string
  @observable employerName?: string
  @observable province?: ProvinceEnum
  @observable phoneNumber?: string
  @observable bpId?: string
  @observable caseId?: string
  @observable preferredContactMethod?: string
  @observable existingCustomer?: boolean
  @observable caslConsent = false
  @observable feedbackRating?: number
  @observable feedbackComments?: string
  @observable modifyingEvent?: ModifyingEventAttributes
  @observable meetingLink?: string
  @observable reminderRequested = true
  @observable loginType?: string
  @observable origin?: AppointmentOriginEnum
  @observable loanAmount?: LoanAmountEnum
  @observable address?: string
  @observable latLong?: LatLongObject
  @observable blockedLocation?: boolean = false

  @computed get branchName(): string {
    return this.branch ? this.branch.name : ''
  }

  @computed get branchAddress(): string {
    return this.branch ? this.branch.address : ''
  }

  @computed get summaryServiceType(): string {
    return `${this.serviceSubType}` + (!this.origin ? ` for ${this.appointmentType} banking` : '')
  }

  @computed get shortDateString(): string {
    if (this.date) {
      return format(this.date, 'EEE, MMMM d yyyy')
    }
    return ''
  }

  @computed get dateTimeString(): string {
    if (this.date && this.timeSlot) {
      return `${format(this.date, 'EEE, MMMM d yyyy')} ${this.timeSlot.time}${this.timeSlot.type}`
    }
    return ''
  }

  @computed get timeString(): string {
    if (this.date) {
      return `${this.timeSlot?.time} ${this.timeSlot?.type}`
    }
    return ''
  }

  @computed get datetime(): Date | null {
    if (this.date && this.timeSlot) {
      const [startHours, startMinutes] = this.timeSlot.time.split(':').map(numberString => Number(numberString))
      const date = moment(this.date).tz(ALBERTA_TIMEZONE).toDate()

      if (this.timeSlot.type === 'am') {
        date.setHours(startHours === 12 ? startHours - 12 : startHours, startMinutes)
      } else {
        date.setHours(startHours === 12 ? startHours : startHours + 12, startMinutes)
      }

      return date
    }
    return null
  }

  @computed get timezoneLabel(): string {
    return moment(this.date).add(12, 'hours').tz('America/Edmonton').isDST() ? 'MDT' : 'MST'
  }

  @computed get appointmentLength(): number {
    return getAppointmentDuration(this.appointmentType, this.serviceSubType)
  }

  @computed get appointmentTimeRange(): string {
    if (this.timeSlot) {
      return computeTimeSlotRange(this.timeSlot, this.appointmentLength, this.timezoneLabel)
    }
    return ''
  }

  @computed get transit(): string {
    return this.branch ? this.branch.transit : ''
  }

  @computed get minDate(): Date {
    const minDate = new Date()

    const originsWithSameDayStart: (AppointmentOriginEnum | undefined)[] = [
      AppointmentOriginEnum.PROSPER,
      AppointmentOriginEnum.GWS,
      AppointmentOriginEnum.MORTGAGE_RENEWAL
    ]
    if (!originsWithSameDayStart.includes(this.origin)) {
      minDate.setDate(minDate.getDate() + 1)
    }

    return minDate
  }

  @computed get evaluateDateTimeRendering(): boolean {
    return (
      !!this.appointmentType &&
      !!this.branch &&
      (!!this.description || this.origin === AppointmentOriginEnum.GWS) &&
      !!this.serviceSubType &&
      !!this.meetingMethod
    )
  }

  @computed get proficiency(): ProficiencyEnum {
    return this.serviceSubType ? calcProficiency(this.serviceSubType) : ProficiencyEnum.LEVEL_0
  }

  @computed get eventModified(): boolean {
    if (this.modifyingEvent === undefined) {
      return false
    }

    if (
      this.branch?.transit !== this.modifyingEvent?.branch.transit ||
      this.date?.getDate() !== this.modifyingEvent?.date.getDate() ||
      this.date?.getMonth() !== this.modifyingEvent?.date.getMonth() ||
      this.date?.getFullYear() !== this.modifyingEvent?.date.getFullYear() ||
      this.timeSlot?.time !== this.modifyingEvent?.timeSlot.time ||
      this.timeSlot?.type !== this.modifyingEvent?.timeSlot.type
    ) {
      return true
    }

    if (
      this.appointmentType !== this.modifyingEvent?.appointmentType &&
      this.serviceSubType === AppointmentSubTypeEnum.CHEQUING_OR_SAVINGS_ACCOUNTS &&
      this.modifyingEvent?.serviceSubType === AppointmentSubTypeEnum.CHEQUING_OR_SAVINGS_ACCOUNTS
    ) {
      return false
    }

    if (this.appointmentType !== this.modifyingEvent?.appointmentType) {
      return true
    }
    return false
  }

  @computed get meetingMethodCategory() {
    return getMeetingMethodCategory(this.meetingMethod)
  }

  @computed get addToCalendarEvent() {
    if (
      this.appointmentType &&
      this.branch &&
      this.bookingId &&
      this.date &&
      this.serviceSubType &&
      this.timeSlot &&
      this.meetingMethod
    ) {
      const description = [
        `<b>Branch:</b> ${this.branchName}`,
        `<b>Appointment type:</b> ${this.summaryServiceType}`,
        `<b>Booking ID:</b> ${this.bookingId}`,
        this.meetingMethod === MeetingMethodEnum.ONLINE && this.meetingLink
          ? `<b>Join with Google Meet:</b> ${this.meetingLink.replace('https://', '')}`
          : null
      ].join('<br>')

      const dateString = `${moment(this.date).format('YYYY-MM-DD')} ${this.timeSlot.time} ${this.timeSlot.type}`

      return {
        title: `${i18n.__(copy.MeetingMethod.Text[this.meetingMethod])} Appointment with ATB`,
        description,
        duration: this.appointmentLength,
        location: this.meetingMethod === MeetingMethodEnum.BRANCH ? this.branchAddress : undefined,
        startDateTime: moment.tz(dateString, 'YYYY-MM-DD HH:mm a', ALBERTA_TIMEZONE).toDate()
      }
    }

    return
  }

  @action
  setAdvisor = (advisor?: Advisor): void => {
    this.advisor = advisor
  }

  @action
  setMeetingMethod = (meetingMethod?: MeetingMethodEnum): void => {
    this.meetingMethod = meetingMethod
  }

  @action
  setAppointmentType = (appointmentType?: AppointmentTypeEnum): void => {
    this.appointmentType = appointmentType
  }

  @action
  setBranch = (branch: Branch | null): void => {
    this.branch = branch
  }

  @action
  setBookingId = (bookingId?: string): void => {
    this.bookingId = bookingId
  }

  @action
  setDate = (date: Date | null): void => {
    if (date) {
      this.date = new Date(date.toDateString())
    } else {
      this.date = this.defaults.date
    }
  }

  @action
  setDescription = (description?: string): void => {
    this.description = description
  }

  @action
  setEventId = (eventId?: string): void => {
    this.eventId = eventId
  }

  @action
  setLoginType = (loginType: string): void => {
    this.loginType = loginType
  }

  @action
  setServiceSubType = (subType?: AppointmentSubTypeEnum): void => {
    if (subType !== AppointmentSubTypeEnum.LOANS_LINES_OF_CREDIT) {
      this.loanAmount = undefined
    }
    this.serviceSubType = subType
  }

  @action
  setTimeSlot = (timeSlot?: TimeSlot): void => {
    this.timeSlot = timeSlot
  }

  @action
  setBusinessName = (businessName?: string): void => {
    this.businessName = businessName
  }

  @action
  setFirstName = (firstName?: string): void => {
    this.firstName = firstName
  }

  @action
  setLastName = (lastName?: string): void => {
    this.lastName = lastName
  }

  @action
  setEmail = (email?: string): void => {
    this.email = email?.toLowerCase()
  }

  @action
  setEmployerName = (employerName?: string): void => {
    this.employerName = employerName
  }

  @action
  setProvince = (province?: ProvinceEnum): void => {
    this.province = province
  }

  @action
  setPhoneNumber = (phoneNumber?: string): void => {
    this.phoneNumber = phoneNumber
  }

  @action
  setPreferredContactMethod = (method?: string): void => {
    this.preferredContactMethod = method
  }

  @action
  setBPID = (bpId?: string): void => {
    this.bpId = bpId
  }

  @action
  setCaseId = (caseId?: string | null): void => {
    if (caseId !== null) {
      this.caseId = caseId
    }
  }

  @action
  setExistingCustomer = (existingCustomer?: boolean): void => {
    this.existingCustomer = existingCustomer
  }

  @action
  setCaslConsent = (caslConsent: boolean): void => {
    this.caslConsent = caslConsent
  }

  @action
  setFeedbackRating = (feedbackRating?: number): void => {
    this.feedbackRating = feedbackRating
  }

  @action
  setFeedbackComments = (feedbackComments?: string): void => {
    this.feedbackComments = feedbackComments
  }

  @action
  setModifyingEvent = (event: ModifyingEventAttributes | undefined): void => {
    this.modifyingEvent = event
  }

  @action
  setMeetingLink = (meetingLink?: string): void => {
    this.meetingLink = meetingLink
  }

  @action
  setReminderRequested = (reminderRequested: boolean): void => {
    this.reminderRequested = reminderRequested
  }

  @action
  setOrigin = (origin?: AppointmentOriginEnum): void => {
    this.origin = origin
    if (origin) i18n.setLocale(origin)
  }

  @action
  setLoanAmount = (loanAmount?: LoanAmountEnum): void => {
    this.loanAmount = loanAmount
  }

  @action
  setAddress = (address?: string): void => {
    this.address = address
  }

  @action
  setLatLong = (lat: number, lng: number): void => {
    this.latLong = { latitude: lat, longitude: lng }
  }

  @action
  setBlockedLocation = (blockedLocation?: boolean): void => {
    this.blockedLocation = blockedLocation
  }

  @action
  preFillFields = (preFilledfields: PreFillFieldsInput): void => {
    if (preFilledfields.firstName) this.firstName = preFilledfields.firstName
    if (preFilledfields.lastName) this.lastName = preFilledfields.lastName
    if (preFilledfields.email) this.email = preFilledfields.email ? preFilledfields.email.toLowerCase() : ''
    if (preFilledfields.phoneNumber) this.phoneNumber = preFilledfields.phoneNumber
    if (preFilledfields.existingCustomer) this.existingCustomer = !!preFilledfields.existingCustomer
    if (preFilledfields.bpId) this.bpId = preFilledfields.bpId
    if (preFilledfields.description) this.description = preFilledfields.description

    this.preFilledFields = {
      firstName: !!preFilledfields.firstName,
      lastName: !!preFilledfields.lastName,
      email: !!preFilledfields.email,
      phoneNumber: !!preFilledfields.phoneNumber,
      existingCustomer: preFilledfields.existingCustomer !== undefined ? true : false,
      description: !!preFilledfields.description
    }
  }

  @action
  clearStore = (): void => {
    this.preFilledFields = {}
    this.advisor = undefined
    this.meetingMethod = undefined
    this.appointmentType = undefined
    this.branch = null
    this.bookingId = undefined
    this.date = this.defaults.date
    this.description = undefined
    this.eventId = undefined
    this.serviceSubType = undefined
    this.timeSlot = undefined
    this.businessName = undefined
    this.firstName = undefined
    this.lastName = undefined
    this.email = undefined
    this.employerName = undefined
    this.province = undefined
    this.phoneNumber = undefined
    this.bpId = undefined
    this.caseId = undefined
    this.preferredContactMethod = undefined
    this.existingCustomer = undefined
    this.caslConsent = false
    this.feedbackRating = undefined
    this.feedbackComments = undefined
    this.modifyingEvent = undefined
    this.meetingLink = undefined
    this.reminderRequested = true
    this.loginType = undefined
    this.origin = undefined
    this.loanAmount = undefined
    this.blockedLocation = false
  }

  @action
  bookingStoreToString = (): string => {
    const result = {}
    for (const field of Object.keys(this)) {
      result[field] = this[field] || 'undefined'
    }
    return JSON.stringify(result)
  }
}

export const bookingStore = new BookingStore()

export const BookingStoreContext = createContext<BookingStore>(bookingStore)

export const useBookingStore = () => useContext<BookingStore>(BookingStoreContext)
