import { AppointmentOriginEnum, FeedbackOperationEnum, i18n, copy } from '@atbdigitalteam/obs-shared-components'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Form, Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { globalHistory } from '@reach/router'

import { useBookingStore } from '../../../injectables'
import { StringReplace, BoldSubString } from '../../../translations'
import { AppointmentSummary } from '../../components/AppointmentSummary'
import { handleSubmit, FeedbackForm, FeedbackSubmitted, FeedbackValues } from '../../components/Feedback'
import { PageTitle } from '../../components/PageTitle'
import { IntroParagraph } from '../ConfirmationPage/IntroParagraph'
import { required, sensitiveInfo } from '../../../utils/fieldValidation'

const useStyles = makeStyles({
  divider: {
    marginTop: '16px',
    maxHeight: '0',
    border: 'solid 0.5px #e4e4e4',
    margin: '0.5rem 0 0.5rem 0'
  },
  bookingIdContainer: {
    display: 'inline-grid',
    gridTemplateColumns: 'auto auto',
    gridGap: '0.25rem'
  },
  label: {
    fontWeight: 600
  },
  gridItem: {
    padding: '8px 0'
  },
  title: {
    marginTop: '70px'
  },
  feedbackButton: {
    marginBottom: '80px'
  }
})

export const BaseModifyFeedbackComponent = () => {
  const classes = useStyles()
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false)
  const {
    advisor,
    addToCalendarEvent,
    appointmentTimeRange,
    branchName,
    branchAddress,
    bookingId,
    email,
    meetingMethod,
    shortDateString,
    timeString,
    summaryServiceType,
    feedbackRating,
    feedbackComments,
    meetingLink,
    phoneNumber,
    origin,
    setFeedbackRating,
    setFeedbackComments,
    bookingStoreToString,
    existingCustomer,
    province,
    employerName,
    timezoneLabel,
    setBookingId
  } = useBookingStore()
  const bookingStore = bookingStoreToString()

  useEffect(() => {
    const unsubscribe = globalHistory.listen(({ action }) => {
      if (action === 'POP') {
        setBookingId(undefined)
      }
    })

    return unsubscribe
  }, [setBookingId])

  return (
    <Formik
      initialValues={
        {
          feedbackRating: feedbackRating || 0,
          feedbackComments: feedbackComments || ''
        } as FeedbackValues
      }
      onSubmit={(values: FeedbackValues, actions: FormikHelpers<FeedbackValues>) =>
        handleSubmit(
          values,
          actions,
          feedbackRating!,
          feedbackComments!,
          FeedbackOperationEnum.MODIFY,
          setFeedbackSubmitted,
          bookingStore,
          origin!,
          bookingId
        )
      }
    >
      {({ isSubmitting, values }) => (
        <Form data-testid='modify-feedback-form'>
          <Grid item className={classes.title}>
            <PageTitle
              role='status'
              littleTitle={i18n.__(copy.Feedback.LittleTitle)}
              bigTitle={i18n.__(copy.Feedback.BigTitle)}
            />
          </Grid>
          {meetingMethod && (
            <Grid item className={classes.gridItem}>
              <IntroParagraph
                branchName={branchName}
                dateString={shortDateString}
                timeString={timeString}
                meetingMethod={meetingMethod}
                phoneNumber={phoneNumber}
                timezoneLabel={timezoneLabel}
                origin={origin}
              />
            </Grid>
          )}
          <Grid item className={classes.gridItem}>
            <Typography variant='body2' data-testid='appointment-confirmation-text'>
              <>
                {BoldSubString(
                  StringReplace(i18n.__(copy.Confirm.ParagraphText), { '%CUSTOMER_EMAIL%': email || '' }),
                  'text'
                )}
              </>
            </Typography>
          </Grid>
          <Grid item className={classes.gridItem}>
            <AppointmentSummary
              branchName={branchName}
              branchAddress={branchAddress}
              serviceSummary={summaryServiceType}
              date={shortDateString}
              advisor={advisor}
              timeRange={appointmentTimeRange}
              meetingMethod={meetingMethod}
              meetingLink={meetingLink}
              addToCalendarEvent={addToCalendarEvent}
              bookingId={bookingId}
              origin={origin}
              enhanced
              province={province}
              existingGWS={existingCustomer && origin === AppointmentOriginEnum.GWS}
              employerName={employerName}
            />
          </Grid>
          <Grid item className={classes.gridItem}>
            <div className={classes.divider} />
          </Grid>
          <Grid item className={classes.feedbackButton}>
            {!feedbackSubmitted && (
              <FeedbackForm
                isSubmitting={isSubmitting}
                ratingValue={values.feedbackRating}
                textValue={values.feedbackComments}
                onChangeRating={(value: number) => setFeedbackRating(value)}
                onChangeText={(value: string) => setFeedbackComments(value)}
                validate={{
                  rating: value => required(value, i18n.__(copy.Validation.required.feedbackRating)),
                  feedbackComments: value => sensitiveInfo(value)
                }}
                validateOnBlur
              />
            )}
            {feedbackSubmitted && <FeedbackSubmitted />}
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export const ModifyFeedbackComponent = observer(BaseModifyFeedbackComponent)
