const newLongStyle = /[a-zA-Z0-9]*\+([A-Z0-9]{6})/
const newShortStyle = /[A-Z0-9]{6}/

export const BookingIdStyleEnum = {
  NEW_LONG: 'newlong',
  NEW_SHORT: 'newshort',
  OLD: 'old'
}

export function detectBookingIdStyle(bookingId: string) {
  if (bookingId.match(newLongStyle)) {
    return BookingIdStyleEnum.NEW_LONG
  }
  if (bookingId.match(newShortStyle)) {
    return BookingIdStyleEnum.NEW_SHORT
  }
  return BookingIdStyleEnum.OLD
}

export function getShortBookingId(bookingId: string | undefined) {
  if (bookingId) {
    const style = detectBookingIdStyle(bookingId)
    if (style === BookingIdStyleEnum.NEW_SHORT || style === BookingIdStyleEnum.OLD) {
      return bookingId
    } else {
      const matchedBookingId = bookingId.match(newLongStyle)
      if (matchedBookingId && matchedBookingId[1]) {
        return matchedBookingId[1]
      }
      return matchedBookingId
    }
  }
  return undefined
}

export function parseEmail(email: string | undefined) {
  if (email) {
    return email.replace(/[^0-9a-zA-Z]/g, '')
  }
  return ''
}
