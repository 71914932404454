import { Redirect, Router } from '@reach/router'
import React from 'react'

import {
  CancelPage,
  ConfirmationPage,
  DetailsPage,
  FeedbackPage,
  FindALocationPage,
  FindATimePage,
  GwsPage,
  OBSLitePage,
  LandingPage,
  ModifyPage,
  ProsperPage,
  SummaryPage
} from '../modules'
import { routes } from './routes'

export const AppRouter = () => (
  <main id='main-content'>
    <Router>
      <LandingPage path={routes.root.path} branchId='' />
      <LandingPage path={`${routes.root.path}:branchId`} branchId='' />

      <DetailsPage path={routes.details.path} branchId='' />
      <DetailsPage path={`${routes.details.path}:branchId`} branchId='' />

      <SummaryPage path={routes.summary.path} />
      <ConfirmationPage path={routes.confirmation.path} />
      <CancelPage path={routes.cancel.path} />
      <ModifyPage path={routes.modify.path} />
      <FindALocationPage path={routes.findALocation.path} />
      <FindATimePage path={routes.findATime.path} />

      <Redirect from='/prosper/' to={routes.prosper.path} />
      <ProsperPage path={routes.prosper.path} />

      <Redirect from='/gws/' to={routes.gws.path} />
      <GwsPage path={routes.gws.path} />

      <OBSLitePage path={`${routes.obs.path}:branchId`} branchId='' />

      {['dev', 'uat'].includes(process.env.FE_ENVIRONMENT!) && <FeedbackPage path={routes.feedback.path} />}
    </Router>
  </main>
)
