export const parseHexString = (value: string): number => parseInt(value, 16)

export interface Rgb {
  r: number
  g: number
  b: number
}

// Parses a hex string into RGB channels. This is mainly uses for converting Hex colours into rgba colours
export const hexToRgbParts = (hex: string): Rgb => {
  const colourCode = parseHexString(hex.substring(1, hex.length))
  /* tslint:disable:no-bitwise */
  const r = (colourCode >> 16) & 255
  const g = (colourCode >> 8) & 255
  const b = colourCode & 255
  /* tslint:enable:no-bitwise */
  return { r, g, b }
}

// Converts a Hex colour code to rgba with the provided opacity
export const hexToRgba = (hex: string, alpha: number): string => {
  const { r, g, b }: Rgb = hexToRgbParts(hex)
  return `rgba(${r},${g},${b},${alpha})`
}
