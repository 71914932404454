import { DialogueDisplay } from '@atbdigitalteam/obs-shared-components'
import React from 'react'

interface NoTimeZoneConversionProps {
  timezone: string
}

export const NoTimeZoneConversion = ({ timezone }: NoTimeZoneConversionProps) => (
  <DialogueDisplay
    id='no-timezone-conversion'
    displayMessage={
      <>
        These times are displayed in {timezone} and <b>are not converted</b> to your current time zone
      </>
    }
  />
)
