import { ApiRequest } from './ApiRequest'

const API_SERVER = process.env.API_SERVER || ''
const BASE_PATH = `${API_SERVER}/api/googleMapsHealth`

export const googleMapsHealth = () => {
  return new ApiRequest()
    .get(BASE_PATH)
    .send()
    .then(response => {
      return response.data
    })
    .catch(err => {
      return err
    })
}
