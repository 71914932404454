import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { ReactElement } from 'react'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginBottom: '8px'
  },
  divider: {
    width: '40px',
    height: '0px',
    border: 'solid 1px #FF7F30',
    marginTop: '16px',
    marginBottom: '8px',
    backgroundColor: '#FF7F30'
  },
  bigTitle: {
    fontSize: '1.125rem',
    fontWeight: 600,
    lineHeight: '1.5rem',
    marginTop: '5px',
    color: '#000025'
  },
  captionTitle: {
    fontWeight: 600,
    fontSize: '0.75rem',
    lineHeight: '1rem',
    color: '#000025'
  }
})

export interface PageTitleProps {
  littleTitle: string
  bigTitle: string
  step?: string
  role?: string
}

export const PageTitle = ({ littleTitle, bigTitle, step, role }: PageTitleProps): ReactElement => {
  const classes = useStyles()

  return (
    <div className={classes.root} data-testid='page-title'>
      {littleTitle && (
        <Typography variant='caption' variantMapping={{ caption: 'h1' }} className={classes.captionTitle}>
          {littleTitle}
        </Typography>
      )}
      <Typography role={role} variant='h5' component='h1' className={classes.bigTitle}>
        {bigTitle}
      </Typography>
      <div className={classes.divider} />
      {step && <Typography variant='body1'>{step}</Typography>}
    </div>
  )
}
