import { makeStyles } from '@material-ui/core/styles'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { autorun } from 'mobx'
import { observer } from 'mobx-react'
import { useSnackbar, SnackbarKey } from 'notistack'
import React, { useEffect } from 'react'
import { useNotificationService, GrowNotification } from '../../injectables'

import { UIState } from '../../styles'
import { stateStyles } from '../../themes'
import { UINotificationAction } from './UINotificationAction'
import { UINotificationMessage } from './UINotificationMessage'

const DEFAULT_DURATION = 7000

const useStyles = makeStyles(stateStyles)

const BaseNotificationHandler = () => {
  const classes = useStyles()
  const { dismissNotification, notifications } = useNotificationService()
  const { closeSnackbar, enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    autorun(() => {
      notifications.forEach((item: GrowNotification) => {
        enqueueSnackbar(item.message, {
          autoHideDuration: item.autoDismissTime ?? DEFAULT_DURATION,
          content: (key: SnackbarKey) => (
            <SnackbarContent
              className={classes[item.appearance ?? UIState.Info]}
              action={<UINotificationAction onClose={() => closeSnackbar(key)} />}
              message={<UINotificationMessage Icon={item.Icon} message={item.message} />}
              role='alert'
            />
          )
        })
        dismissNotification(item)
      })
    })
  }, [notifications, classes, closeSnackbar, dismissNotification, enqueueSnackbar])

  return null
}

export const NotificationHandler = observer(BaseNotificationHandler)
