export interface Route {
  title: string
  path: string
  relativePath?: string
  subRoutes?: Route[]
}

export interface RouteMap {
  root: Route
  login: Route
  details: Route
  findALocation: Route
  findATime: Route
  gws: Route
  obs: Route
  summary: Route
  confirmation: Route
  cancel: Route
  modify: Route
  prosper: Route
  feedback: Route
}

export const routes: RouteMap = {
  root: {
    title: 'root',
    path: '/'
  },
  login: {
    title: 'root',
    path: '/login'
  },
  details: {
    title: 'details',
    path: '/book/'
  },
  findALocation: {
    title: 'find a location',
    path: '/find-a-location/'
  },
  findATime: {
    title: 'find a time',
    path: '/find-a-time/'
  },
  gws: {
    title: 'group wealth services',
    path: '/gws/book/'
  },
  obs: {
    title: 'obsLite',
    path: '/obs/'
  },
  summary: {
    title: 'summary',
    path: '/summary/'
  },
  confirmation: {
    title: 'confirmation',
    path: '/confirmation/'
  },
  cancel: {
    title: 'cancel',
    path: '/cancel/'
  },
  modify: {
    title: 'modify',
    path: '/modify/'
  },
  prosper: {
    title: 'prosper',
    path: '/prosper/book/'
  },
  feedback: {
    title: 'feedback',
    path: '/feedback/'
  }
}
