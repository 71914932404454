import { CustomTextField } from '@atbdigitalteam/obs-shared-components'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import React from 'react'

interface BookingInformationFieldProps {
  onChangeBookingId: (value: string) => void
  onChangeEmail: (value: string) => void
  validate?: {
    bookingId?: (value: string) => string | undefined
    email?: (value: string) => string | undefined
  }
  validateOnBlur?: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      margin: 0
    },
    gridItem: {
      width: '100%',
      marginTop: 8
    },
    textField: {
      width: '100%',
      height: 36,
      borderRadius: 8,
      backgroundColor: 'rgba(237, 240, 242, 0.6)'
    }
  })
)

export const BookingInformation = ({
  onChangeBookingId,
  onChangeEmail,
  validate,
  validateOnBlur
}: BookingInformationFieldProps) => {
  const classes = useStyles()

  return (
    <Grid
      container
      justifyContent='space-between'
      spacing={1}
      direction='column'
      data-testid='booking-information'
      className={classes.root}
    >
      <Grid item className={classes.gridItem} style={{ marginRight: 4 }}>
        <CustomTextField
          name='bookingId'
          id='booking-id'
          onChange={onChangeBookingId}
          placeholder='Booking ID'
          ariaLabel='Booking ID'
          ariaRequired='true'
          validate={validate?.bookingId}
          validateOnBlur={validateOnBlur}
        />
      </Grid>
      <Grid item className={classes.gridItem}>
        <CustomTextField
          name='email'
          id='email-field'
          onChange={onChangeEmail}
          placeholder='Email'
          ariaRequired='true'
          validate={validate?.email}
          validateOnBlur={validateOnBlur}
        />
      </Grid>
    </Grid>
  )
}
