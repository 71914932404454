import {
  AppointmentTypeEnum,
  Branch,
  DayOfWeekEnum,
  LoanAmountEnum,
  ProficiencyEnum,
  TimeOfDayEnum
} from '@atbdigitalteam/obs-shared-components'
import { action, computed, observable } from 'mobx'
import { createContext, useContext, useEffect } from 'react'

import { LatLongObject } from '../../modules/components/LocationSearch'
import { getBranchList, getPaginatedBranchList } from '../../requests/branches'

export const DefaultValues = {
  nextIndex: 0,
  totalBranches: 9999,
  numberOfResults: 3,
  maxBranches: 15,
  branches: [],
  isFetching: false,
  onlyShowAvailable: true,
  googleMapsDown: false,
  dayOfWeek: DayOfWeekEnum.ANY,
  timeOfDay: TimeOfDayEnum.ANY
}

export class BranchStore {
  @observable nextIndex: number = DefaultValues.nextIndex
  @observable totalBranches: number = DefaultValues.totalBranches
  @observable numberOfResults: number = DefaultValues.numberOfResults
  @observable maxBranches: number = DefaultValues.maxBranches
  @observable branches: Branch[] = DefaultValues.branches
  @observable isFetching: boolean = DefaultValues.isFetching
  @observable onlyShowAvailable: boolean = DefaultValues.onlyShowAvailable
  @observable googleMapsDown?: boolean = DefaultValues.googleMapsDown
  @observable dayOfWeek?: DayOfWeekEnum = DefaultValues.dayOfWeek
  @observable timeOfDay?: TimeOfDayEnum = DefaultValues.timeOfDay

  @computed get moreAvailable(): boolean {
    return this.branches.length < this.maxBranches && this.nextIndex < this.totalBranches
  }

  @action
  initBranchStore = async (): Promise<void> => {
    return getBranchList().then(results => {
      this.branches = results
    })
  }

  @action
  setBranches = (branches?: Branch[]): void => {
    this.branches = branches || []
  }

  @action
  setIsFetching = (isFetching: boolean): void => {
    this.isFetching = isFetching
  }

  @action
  setOnlyShowAvailable = (onlyShowAvailable: boolean): void => {
    this.onlyShowAvailable = onlyShowAvailable
  }

  @action
  setGoogleMapsDown = (googleMapsDown: boolean): void => {
    this.googleMapsDown = googleMapsDown
  }

  @action
  setDayOfWeek = (dayOfWeek?: DayOfWeekEnum): void => {
    this.dayOfWeek = dayOfWeek
  }

  @action
  setTimeOfDay = (timeOfDay?: TimeOfDayEnum): void => {
    this.timeOfDay = timeOfDay
  }

  @action
  nextPage = async (
    latLong?: LatLongObject,
    appointmentType?: AppointmentTypeEnum,
    proficiency?: ProficiencyEnum,
    loanAmount?: LoanAmountEnum
  ) => {
    this.setIsFetching(true)
    if (this.moreAvailable) {
      const results = await getPaginatedBranchList(
        this.nextIndex,
        this.numberOfResults,
        latLong,
        this.onlyShowAvailable,
        appointmentType,
        proficiency,
        loanAmount,
        this.dayOfWeek,
        this.timeOfDay
      )

      if (results.data.length > 0) {
        const branches = this.branches.slice()

        results.data.forEach(item => {
          if (!this.branches.find(branch => branch.transit === item.transit)) {
            branches.push(item)
          }
        })

        this.branches = branches
      }

      this.nextIndex = results.nextIndex
      this.totalBranches = results.totalBranches
    }
    this.setIsFetching(false)
  }

  @action
  clearStore = () => {
    this.nextIndex = DefaultValues.nextIndex
    this.numberOfResults = DefaultValues.numberOfResults
    this.totalBranches = DefaultValues.totalBranches
    this.maxBranches = DefaultValues.maxBranches
    this.branches = DefaultValues.branches
    this.onlyShowAvailable = DefaultValues.onlyShowAvailable
    this.googleMapsDown = DefaultValues.googleMapsDown
    this.dayOfWeek = DefaultValues.dayOfWeek
    this.timeOfDay = DefaultValues.timeOfDay
  }

  @action
  resetSearchResults = () => {
    this.nextIndex = DefaultValues.nextIndex
    this.branches = DefaultValues.branches
  }
}

export const branchStore = new BranchStore()

export const BranchContext = createContext<BranchStore>(branchStore)

export const useBranchStore = (initialize = false) => {
  useEffect(() => {
    if (initialize && branchStore.branches.length === 0) {
      branchStore.initBranchStore()
    }
  }, [])

  return useContext<BranchStore>(BranchContext)
}
