import { ApiRequest } from './ApiRequest'
import { QueryParams } from './QueryParams'

const API_SERVER = process.env.API_SERVER || ''
const BASE_PATH = `${API_SERVER}/api/geocode`

export const geocode = async (address: string) => {
  const queryParams = new QueryParams({
    address
  })

  return new ApiRequest()
    .get(`${BASE_PATH}?${queryParams.toString()}`)
    .send()
    .then(response => {
      return response.data
    })
}

export const reverseGeocode = async (latlng: string) => {
  const queryParams = new QueryParams({
    latlng
  })

  return new ApiRequest()
    .get(`${BASE_PATH}?${queryParams.toString()}`)
    .send()
    .then(response => {
      return response.data
    })
}
