import { reverseGeocode } from '../requests/geocode'

// disabling no-explicit-any as the GeolocationPosition type requires typescript >= 4.1.x
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const geoSuccess = (position: any) => {
  return reverseGeocode(`${position.coords.latitude},${position.coords.longitude}`)
}

export const promptAndGetLocation = async () => {
  const options = { timeout: 30000 }
  const pos = await new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options)
  })
  return geoSuccess(pos)
}
