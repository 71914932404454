import {
  AppointmentOriginEnum,
  MeetingMethodEnum,
  StringReplace,
  i18n,
  copy
} from '@atbdigitalteam/obs-shared-components'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { BoldSubString } from '../../../translations'
export interface IntroParagraphProps {
  branchName: string
  dateString: string
  timeString: string
  meetingMethod: MeetingMethodEnum
  timezoneLabel: string
  phoneNumber?: string
  origin?: AppointmentOriginEnum
}

export const IntroParagraph = ({
  branchName,
  dateString,
  timeString,
  meetingMethod,
  phoneNumber,
  timezoneLabel
}: IntroParagraphProps) => {
  const introParagraphReplacements = {
    [MeetingMethodEnum.BRANCH]: {
      '%DATE%': dateString,
      '%TIME%': timeString,
      '%BRANCH_NAME%': branchName,
      '%TIMEZONE_LABEL%': timezoneLabel
    },
    [MeetingMethodEnum.ONLINE]: {
      '%DATE%': dateString,
      '%TIME%': timeString,
      '%TIMEZONE_LABEL%': timezoneLabel,
      '%SPECIALIST_LABEL%': i18n.__(copy.SpecialistLabel)
    },
    [MeetingMethodEnum.PHONE]: {
      '%DATE%': dateString,
      '%TIME%': timeString,
      '%PHONE_NUMBER%': phoneNumber || '',
      '%TIMEZONE_LABEL%': timezoneLabel,
      '%SPECIALIST_LABEL%': i18n.__(copy.SpecialistLabel)
    }
  }

  const textWithReplacements = StringReplace(
    i18n.__(copy.Confirm.IntroParagraph[meetingMethod]),
    introParagraphReplacements[meetingMethod]
  )

  return (
    <Typography variant='body2' data-testid='appointment-confirmation-intro-text'>
      <>{BoldSubString(textWithReplacements, 'intro-text')}</>
    </Typography>
  )
}
