import { createStyles, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import React from 'react'

import { i18n, copy } from '@atbdigitalteam/obs-shared-components'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      margin: 0
    },
    thankYou: {
      marginTop: '8px'
    },
    submittedLabel: {
      marginBottom: '50px'
    },
    mailIcon: {
      color: 'rgb(0, 114, 240)',
      fontSize: '48px',
      marginTop: '50px'
    }
  })
)

export const FeedbackSubmitted = () => {
  const classes = useStyles()

  return (
    <React.Fragment>
      <div role='status' data-testid='feedback-submitted' className={classes.root}>
        <Grid container justifyContent='center' spacing={1}>
          <Grid item container justifyContent='center' xs={12}>
            <MailOutlineIcon className={classes.mailIcon} />
          </Grid>
          <Grid item container justifyContent='center' xs={12}>
            <Typography className={classes.thankYou} component='h3' variant='h6'>
              {'Thank you!'}
            </Typography>
          </Grid>
          <Grid item container justifyContent='center' xs={12}>
            <Typography className={classes.submittedLabel} variant='body2'>
              {i18n.__(copy.Feedback.SubmittedLabel)}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  )
}
