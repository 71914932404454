import { FeedbackOperationEnum, i18n, copy } from '@atbdigitalteam/obs-shared-components'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Form, Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import React, { useState } from 'react'

import { useBookingStore } from '../../../injectables'
import { handleSubmit, FeedbackForm, FeedbackSubmitted, FeedbackValues } from '../../components/Feedback'
import { PageTitle } from '../../components/PageTitle'
import { required, sensitiveInfo } from '../../../utils/fieldValidation'

const useStyles = makeStyles({
  divider: {
    marginTop: '16px',
    maxHeight: '0',
    border: 'solid 0.5px #e4e4e4',
    margin: '0.5rem 0 0.5rem 0'
  },
  title: {
    marginTop: '70px'
  }
})

export const BaseCancelFeedbackComponent = () => {
  const classes = useStyles()
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false)
  const {
    firstName,
    bookingId,
    feedbackRating,
    feedbackComments,
    origin,
    setFeedbackRating,
    setFeedbackComments,
    bookingStoreToString
  } = useBookingStore()
  const bookingStore = bookingStoreToString()
  return (
    <Formik
      initialValues={{
        feedbackRating: feedbackRating || 0,
        feedbackComments: feedbackComments || ''
      }}
      onSubmit={(values: FeedbackValues, actions: FormikHelpers<FeedbackValues>) =>
        handleSubmit(
          values,
          actions,
          feedbackRating!,
          feedbackComments!,
          FeedbackOperationEnum.CANCEL,
          setFeedbackSubmitted,
          bookingStore,
          origin!,
          bookingId
        )
      }
    >
      {({ isSubmitting, values }) => (
        <Form data-testid='cancel-feedback-form' className={classes.title}>
          <Grid item>
            <PageTitle
              role='status'
              littleTitle={i18n.__(copy.Cancel.LittleTitle)}
              bigTitle={i18n.__(copy.Cancel.AppointmentCancelledTitle)}
            />
          </Grid>
          <Grid item>
            <Typography variant='body2'>
              {firstName
                ? i18n.__(copy.Cancel.AppointmentCancelledCaptionWithName).replace('%FIRSTNAME%', firstName!)
                : i18n.__(copy.Cancel.AppointmentCancelledCaption)}
            </Typography>
          </Grid>
          <Grid item>
            <div className={classes.divider} />
          </Grid>
          <Grid item>
            {!feedbackSubmitted && (
              <FeedbackForm
                isSubmitting={isSubmitting}
                ratingValue={values.feedbackRating}
                textValue={values.feedbackComments}
                onChangeRating={(value: number) => setFeedbackRating(value)}
                onChangeText={(value: string) => setFeedbackComments(value)}
                validate={{
                  rating: value => required(value, i18n.__(copy.Validation.required.feedbackRating)),
                  feedbackComments: value => sensitiveInfo(value)
                }}
                validateOnBlur
              />
            )}
            {feedbackSubmitted && <FeedbackSubmitted />}
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export const CancelFeedbackComponent = observer(BaseCancelFeedbackComponent)
