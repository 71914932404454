import { copy, i18n } from '@atbdigitalteam/obs-shared-components'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import FilledInput from '@material-ui/core/FilledInput'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import React, { useEffect, useState } from 'react'
import { useBookingStore } from '../../injectables/stores/BookingStore'
import { useBranchStore } from '../../injectables/stores/BranchStore'
import { promptAndGetLocation } from '../../utils/geolocation'

type TextStyleProps = {
  color: string
}
const useStyles = makeStyles<Theme, TextStyleProps>(() =>
  createStyles({
    inputField: {
      fontSize: '16px',
      paddingTop: '12px',
      paddingBottom: '12px',
      paddingLeft: '14px',
      paddingRight: '14px',
      backgroundColor: '#ffffff',
      borderRadius: 4,
      border: 'solid 1px #75757B',
      '&:hover': {
        border: 'solid 1px #63666A'
      },
      color: ({ color }) => color,
      '&::placeholder': {
        opacity: 1
      }
    },
    label: {
      marginBottom: '8px',
      display: 'grid',
      gridTemplateColumns: 'auto auto'
    },
    labelText: {
      fontSize: '14px'
    },
    link: {
      fontSize: '12px',
      '&:hover': {
        textDecoration: 'none'
      }
    },
    disabled: {
      cursor: 'not-allowed'
    }
  })
)

export interface LocationSearchProps {
  setIsUserInput: (userInputted: boolean) => void
  disabled?: boolean
}

export interface LatLongObject {
  latitude: number
  longitude: number
}

export const LocationSearch = ({ setIsUserInput, disabled = false }: LocationSearchProps) => {
  const ref = React.useRef<HTMLInputElement>(null)
  const { address, appointmentType, loanAmount, proficiency, setAddress, setLatLong, setBlockedLocation } =
    useBookingStore()
  const { nextPage, resetSearchResults } = useBranchStore()

  const [autoCompleteWidget, setAutoCompleteWidget] = useState<google.maps.places.Autocomplete | undefined>(undefined)
  const [inputValue, setInputValue] = React.useState<string>('')
  const classes = useStyles({ color: inputValue ? '#44444D' : '#75757B' })

  // The following interface is temporary until we upgrade to TypeScript 4.0
  interface GeolocationPositionError {
    code: number
    PERMISSION_DENIED: number
  }

  const handleInput = () => {
    if (autoCompleteWidget) {
      const formatted_address = autoCompleteWidget.getPlace().formatted_address
      if (formatted_address) {
        resetSearchResults()
        setAddress(formatted_address)
      }
      setIsUserInput(true)
    }
  }

  const handleUseLocationClick = async () => {
    setBlockedLocation(false)
    setAddress(undefined)
    resetSearchResults()

    try {
      const coord = await promptAndGetLocation()

      // Used to prevent duplicate calls to the geocoding endpoint
      setIsUserInput(false)

      if (coord) {
        const {
          formatted_address,
          geometry: {
            location: { lat, lng }
          }
        } = coord.results[0] || { geometry: { location: {} } }
        setLatLong(lat, lng)
        setAddress(formatted_address)
        nextPage({ latitude: lat, longitude: lng }, appointmentType, proficiency, loanAmount)
      }
    } catch (err) {
      const error = err as GeolocationPositionError
      // User denied Geolocation
      if (error.code && error.code === error.PERMISSION_DENIED) {
        setBlockedLocation(true)
      }
    }
  }

  useEffect(() => {
    if (ref.current && !autoCompleteWidget) {
      setAutoCompleteWidget(
        new window.google.maps.places.Autocomplete(ref.current, {
          componentRestrictions: { country: ['CA'] },
          fields: ['formatted_address', 'place_id'],
          bounds: {
            north: 60,
            south: 49,
            east: -110,
            west: -120
          }
        })
      )
    }
  }, [ref, autoCompleteWidget])

  useEffect(() => {
    if (autoCompleteWidget) {
      window.google.maps.event.addListener(autoCompleteWidget, 'place_changed', handleInput)
    }
  }, [autoCompleteWidget])

  useEffect(() => {
    if (address) {
      setInputValue(address)
    }
  }, [address])

  return (
    <div className='location-search'>
      <label className={classes.label}>
        <Typography variant='body2' className={classes.labelText}>
          Find your nearest branch
        </Typography>
        <Typography align='right' variant='body2'>
          <Link
            component='button'
            type='button'
            data-testid='use-my-location-link'
            aria-label='Use my location'
            className={classes.link}
            onClick={handleUseLocationClick}
          >
            Use my location
          </Link>
        </Typography>
      </label>
      <FilledInput
        data-testid='location-search-input'
        id='location-search-input'
        inputRef={ref}
        fullWidth
        classes={{
          input: classes.inputField,
          disabled: classes.disabled
        }}
        disabled={disabled}
        disableUnderline
        value={inputValue}
        placeholder={i18n.__(copy.LocationSearchPlaceholder)}
        onChange={e => setInputValue(e.target.value)}
        onKeyDown={event => {
          /* If we dont block the enter key here, hitting enter can submit
             the form when navigating back to find-a-location */
          if (event.key === 'Enter') {
            event.preventDefault()
          }
        }}
      />
    </div>
  )
}
