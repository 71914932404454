import { SvgIconProps } from '@material-ui/core/SvgIcon'
import CheckCircle from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import NotificationImportant from '@material-ui/icons/NotificationImportant'
import Warning from '@material-ui/icons/Warning'
import { action, computed, observable } from 'mobx'
import { createContext, useContext, ComponentType, ReactElement } from 'react'
import { v4 as createUUID } from 'uuid'

import { UIState } from '../../styles'

export interface GrowNotification {
  appearance?: UIState
  autoDismissTime?: number
  id: string
  Icon: ComponentType<SvgIconProps>
  message: ReactElement | string
}

export class NotificationService {
  private stateIconMap: Map<UIState, ComponentType<SvgIconProps>> = new Map<UIState, ComponentType<SvgIconProps>>([
    [UIState.Error, ErrorIcon],
    [UIState.Info, NotificationImportant],
    [UIState.Success, CheckCircle],
    [UIState.Warning, Warning]
  ])

  @observable notificationMap: Map<string, GrowNotification> = new Map<string, GrowNotification>()

  private pushNotification = (notification: GrowNotification): void => {
    this.notificationMap.set(notification.id, notification)
  }

  @computed get notifications(): GrowNotification[] {
    return Array.from(this.notificationMap.values())
  }

  @action createErrorNotification = (errorMessage: string): void => {
    this.createNotification(errorMessage, UIState.Error)
  }

  @action createNotification = (message: ReactElement | string, appearance: UIState, autoDismissTime?: number): void =>
    this.pushNotification({
      message,
      appearance,
      Icon: this.getIconForState(appearance),
      autoDismissTime,
      id: createUUID()
    })

  @action dismissNotification = (notification: GrowNotification): boolean =>
    this.notificationMap.delete(notification.id)

  getIconForState = (state: UIState): ComponentType<SvgIconProps> => this.stateIconMap.get(state)!
}

export const notificationService = new NotificationService()

export const NotificationServiceContext = createContext<NotificationService>(notificationService)

export const useNotificationService = () => useContext<NotificationService>(NotificationServiceContext)
