import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { RouteComponentProps } from '@reach/router'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'

import { BookingInfo } from '../../components/BookingInfo/BookingInfo'
import { Feedback } from '../../components/Feedback'
import { CancelConfirmComponent } from './CancelConfirmComponent'
import { CancelFeedbackComponent } from './CancelFeedbackComponent'

const useStyles = makeStyles({
  formRoot: {
    width: '100%',
    maxWidth: '482px',
    marginRight: '8px',
    marginLeft: '8px',
    marginBottom: '80px'
  }
})

export const BaseCancelPage = (_props: RouteComponentProps) => {
  const classes = useStyles()
  const [step, setStep] = useState(1)

  useEffect(() => window.scrollTo(0, 0), [step])

  return (
    <div>
      {step === 1 && <Feedback />}
      <Grid data-testid='cancel-page' container justifyContent='center' alignItems='center' direction='column'>
        <Grid container direction='column' spacing={1} className={classes.formRoot}>
          {step === 1 && <BookingInfo onSuccess={() => setStep(step + 1)} onFailure={() => setStep(3)} />}
          {step === 2 && <CancelConfirmComponent onSuccess={() => setStep(step + 1)} />}
          {step === 3 && <CancelFeedbackComponent />}
        </Grid>
      </Grid>
    </div>
  )
}

export const CancelPage = observer(BaseCancelPage)
