import { useAuth0 } from '@auth0/auth0-react'
import { useCallback, useEffect, useState } from 'react'

export interface Auth0ClientContext {
  logout: (url: string) => void
  loginRedirect: (authError?: string) => void
  token?: string
}

export const useAuth0Client = (branchId?: string): Auth0ClientContext => {
  const [token, setToken] = useState<string>()
  const { getAccessTokenSilently, isAuthenticated, logout: authLogout, loginWithRedirect } = useAuth0()

  const isNumericalBranchId = (id: string) => /^\d+$/.test(id)

  const handleUnload = useCallback(() => {
    authLogout()
  }, [authLogout])

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then((token: string) => setToken(token))
      window.addEventListener('beforeunload', handleUnload)
    }

    return () => {
      window.removeEventListener('beforeunload', handleUnload)
    }
  }, [handleUnload, isAuthenticated])

  const loginRedirect = (authError?: string) => {
    if (branchId && isNumericalBranchId(branchId) && branchId.length === 5) {
      loginWithRedirect({
        appState: { params: branchId },
        branchId,
        authError
      })
    } else if (!isAuthenticated) {
      loginWithRedirect({ authError })
    }
  }

  const logout = (url: string) => authLogout({ returnTo: url })

  return { logout, loginRedirect, token }
}
