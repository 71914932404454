import { ActionButton, Branch } from '@atbdigitalteam/obs-shared-components'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { useState, FocusEvent, LegacyRef } from 'react'

import { BranchCard } from './BranchCard'

export interface BranchListProps {
  options: Branch[]
  value?: Branch | null
  onChange: (value: Branch | null) => void
  onBlur?: () => void
  onShowMore?: () => Promise<void>
}

const useStyles = makeStyles({
  branch: {
    marginBottom: '16px',
    '&:last-child': {
      marginBottom: '0px'
    }
  },
  fieldset: {
    border: 'unset',
    margin: 0,
    padding: 0
  },
  legend: {
    padding: 0
  }
})

export const BranchList = React.forwardRef(
  ({ options, value, onChange, onShowMore, onBlur }: BranchListProps, ref: LegacyRef<HTMLDivElement>) => {
    const [searching, setSearching] = useState(false)
    const classes = useStyles()

    const handleChange = (newValue: Branch | null) => onChange(newValue)

    const handleBlur = (event: FocusEvent) => {
      const list = event.currentTarget
      const elementReceivingFocus = event.relatedTarget

      if (onBlur && !list.contains(elementReceivingFocus as Node)) {
        onBlur()
      }
    }

    return (
      <fieldset className={classes.fieldset} data-testid='branch-list'>
        <legend className={classes.legend}>
          <Typography variant='body2'>Select a branch</Typography>
        </legend>
        <div ref={ref} onBlur={handleBlur}>
          {options.map(branch => (
            <div key={branch.transit} className={classes.branch}>
              <BranchCard
                branch={branch}
                checked={value?.transit === branch.transit}
                onChange={handleChange}
                disabled={branch.available === false} // undefined === true
              />
            </div>
          ))}
        </div>
        {onShowMore && (
          <ActionButton
            style={{ marginTop: '16px' }}
            data-testid='show-more'
            type='button'
            variant='text'
            buttonText='Show more locations'
            submitting={searching}
            onClick={async () => {
              setSearching(true)
              await onShowMore()
              setSearching(false)
            }}
          />
        )}
      </fieldset>
    )
  }
)
