import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '10px',
    marginTop: '68px',
    fontFamily: 'Inter',
    fontWeight: 400,
    color: '#44444D',
    '& span': {
      paddingBottom: '3px'
    }
  },
  links: {
    display: 'flex',
    paddingBottom: '8px',
    '& a': {
      fontSize: '10px',
      padding: '0 5px',
      textDecoration: 'none',
      color: '#0072F0',
      fontFamily: 'Inter'
    }
  }
})

export const Footer = () => {
  const CopyrightYear = new Date().getFullYear()
  const classes = useStyles()

  return (
    <div className={classes.root} id='footer'>
      <div className={classes.links}>
        <a href='https://www.atb.com' rel='noreferrer' target='_blank'>
          atb.com
        </a>
        |
        <a href='https://www.atb.com/contact-us/Pages/default.aspx' rel='noreferrer' target='_blank'>
          Contact us
        </a>
        |
        <a
          href='https://www.atb.com/important-information/privacy-security/Pages/Privacy-and-Security-Tips.aspx?utm_source=atbol&utm_medium=login&utm_campaign=security-commitment-security-tips'
          rel='noreferrer'
          target='_blank'
        >
          Security Tips
        </a>
      </div>
      <span>&copy; ATB Financial {CopyrightYear}. All Rights Reserved.</span>
      <span>Authorized access only. Usage may be monitored.</span>
    </div>
  )
}
