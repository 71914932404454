import {
  ActionButton,
  Advisor,
  AdvisorSelectionField,
  AppointmentSubTypeEnum,
  DatePickerField,
  MeetingMethodEnum,
  MeetingMethodField,
  ProsperServiceSubtypeField,
  TextInputField,
  TimeSlot,
  TimeSlotPickerField,
  AllAdvisorsOption,
  i18n,
  copy,
  DialogueDisplay
} from '@atbdigitalteam/obs-shared-components'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Form, Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'

import Link from '@material-ui/core/Link'
import { useBookingStore, useCalendarStore } from '../../../injectables'
import { getAdvisorList } from '../../../requests'
import { StringReplace } from '../../../translations'
import { minutesToUserFacingTimeString } from '../../../utils/minutesToUserFacingTimeString'
import { PageTitle } from '../PageTitle'
import { DetailsFormValues } from '../../../types'
import { required, sensitiveInfo } from '../../../utils/fieldValidation'
import { NoTimeZoneConversion } from '../NoTimeZoneConversion'

const { required: requiredText } = copy.Validation

export interface ProsperDetailsFormProps {
  bigTitle: string
  littleTitle: string
  submitButtonText: string
  handleSubmit: (values: DetailsFormValues, actions: FormikHelpers<DetailsFormValues>) => void
}

const useStyles = makeStyles({
  formRoot: {
    width: '100%',
    maxWidth: '482px',
    paddingRight: '8px',
    paddingLeft: '8px',
    marginBottom: '80px'
  },
  formItem: {
    width: '100%',
    marginBottom: '8px'
  },
  formItemNoMargin: {
    width: '100%'
  },
  hideItem: {
    visibility: 'hidden'
  },
  inlineLink: {
    textDecoration: 'underline'
  }
})

export const BaseProsperDetailsForm = ({
  bigTitle,
  littleTitle,
  submitButtonText,
  handleSubmit
}: ProsperDetailsFormProps) => {
  const classes = useStyles()
  const [advisors, setAdvisors] = useState<Advisor[]>([AllAdvisorsOption])

  const {
    advisor,
    bookingId,
    meetingMethod,
    meetingMethodCategory,
    appointmentLength,
    appointmentType,
    date,
    branch,
    description,
    loanAmount,
    minDate,
    proficiency,
    serviceSubType,
    timeSlot,
    shortDateString,
    origin,
    setMeetingMethod,
    setDescription,
    setDate,
    setTimeSlot,
    setAdvisor,
    setServiceSubType,
    timezoneLabel
  } = useBookingStore()

  const { availableSlots, availableDates, isFetching } = useCalendarStore()

  useEffect(() => {
    if (branch && meetingMethod && appointmentType && proficiency !== undefined && serviceSubType) {
      getAdvisorList(
        branch.transit,
        appointmentType,
        proficiency,
        serviceSubType,
        meetingMethod,
        bookingId,
        loanAmount,
        origin
      ).then(advisorList => {
        if (advisor && !advisorList.map(a => a.email).includes(advisor.email)) {
          setAdvisor()
        }
        setAdvisors(advisorList)
      })
    }
  }, [branch, appointmentType, serviceSubType, proficiency, meetingMethodCategory])

  return (
    <Formik
      initialValues={
        {
          advisor,
          appointmentType,
          branch,
          date,
          description,
          meetingMethod,
          serviceSubType,
          timeSlot
        } as DetailsFormValues
      }
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isSubmitting, values }) => (
        <Form>
          <Grid
            data-testid='prosper-details-form'
            container
            justifyContent='center'
            alignItems='center'
            direction='column'
          >
            <Grid container direction='column' spacing={2} className={classes.formRoot}>
              <Grid item className={classes.formItemNoMargin}>
                <PageTitle littleTitle={littleTitle} bigTitle={bigTitle} />
              </Grid>

              {values.appointmentType && (
                <Grid item className={classes.formItem}>
                  <MeetingMethodField
                    name='meetingMethod'
                    isProsper
                    onClickOption={(value: string) => {
                      setMeetingMethod(value as MeetingMethodEnum)
                    }}
                    validate={value => required(value, i18n.__(requiredText.meetingMethod))}
                    validateOnBlur
                  />
                </Grid>
              )}
              <Grid item className={classes.formItem}>
                <ProsperServiceSubtypeField
                  name='serviceSubType'
                  onClickOption={(value: string) => {
                    setServiceSubType(value as AppointmentSubTypeEnum)
                  }}
                  validate={value => required(value, i18n.__(requiredText.serviceSubType))}
                  validateOnBlur
                />
              </Grid>

              {availableDates?.length === 0 && (
                <Grid item className={classes.formItem}>
                  <DialogueDisplay
                    id='no-dates-notice'
                    displayMessage={
                      <>
                        {i18n.__(copy.NoAppointmentsMessage.BeforeLink)}
                        <Link className={classes.inlineLink} href={i18n.__(copy.NoAppointmentsMessage.Link.Url)}>
                          {i18n.__(copy.NoAppointmentsMessage.Link.Text)}
                        </Link>
                      </>
                    }
                  />
                </Grid>
              )}
              {(availableDates === undefined || availableDates.length > 0) && (
                <Grid item className={classes.formItem}>
                  <TextInputField
                    name='description'
                    label={i18n.__(copy.Book.DescriptionInput.Label)}
                    placeHolderText={i18n.__(copy.Book.DescriptionInput.PlaceHolderText)}
                    onChange={(value: string) => {
                      setDescription(value)
                    }}
                    validate={value => required(value, i18n.__(requiredText.description)) || sensitiveInfo(value)}
                    validateOnBlur
                    ariaRequired
                  />
                </Grid>
              )}
              {values.meetingMethod &&
                availableDates?.length !== 0 &&
                (advisors?.find(item => item.email === values.advisor?.email) || !values.advisor) && (
                  <Grid item className={classes.formItem}>
                    <AdvisorSelectionField
                      name='advisor'
                      options={advisors}
                      onChange={(event: React.ChangeEvent<{ value: string }>) => {
                        advisors.forEach(teamMember => {
                          if (teamMember.email === event.target.value) {
                            setAdvisor(teamMember)
                          }
                        })
                      }}
                    />
                  </Grid>
                )}

              {availableDates && availableDates.length > 0 && (
                <>
                  <Grid item className={classes.formItem}>
                    <DatePickerField
                      name='date'
                      minDate={minDate}
                      availableDates={availableDates!}
                      onChange={(value: Date | null) => {
                        setDate(value)
                      }}
                      validate={value => required(value, i18n.__(requiredText.date))}
                      validateOnBlur
                    />
                  </Grid>
                  {!isFetching && availableSlots && availableSlots.length > 0 && (
                    <Grid item className={classes.formItemNoMargin}>
                      <TimeSlotPickerField
                        name='timeSlot'
                        timeslots={availableSlots}
                        timezoneLabel={timezoneLabel}
                        onChange={(value: TimeSlot) => {
                          setTimeSlot(value)
                        }}
                        validate={value => required(value, i18n.__(requiredText.timeSlot))}
                        validateOnBlur
                      />
                    </Grid>
                  )}
                  {!isFetching && availableSlots && availableSlots.length === 0 && (
                    <Grid item className={`${classes.formItemNoMargin}`}>
                      <DialogueDisplay
                        id='no-slots-notice'
                        displayMessage={
                          <>
                            {StringReplace(i18n.__(copy.NoAvailableSlotsMessage.BeforeLink), {
                              '%DATE%': shortDateString
                            })}
                            <Link className={classes.inlineLink} href={i18n.__(copy.NoAvailableSlotsMessage.Link.Url)}>
                              {i18n.__(copy.NoAvailableSlotsMessage.Link.Text)}
                            </Link>
                          </>
                        }
                      />
                    </Grid>
                  )}
                  <Grid item className={`${classes.formItemNoMargin}`}>
                    <NoTimeZoneConversion timezone={timezoneLabel} />
                  </Grid>
                </>
              )}

              {isFetching && (
                <Grid item className={classes.formItem}>
                  <CircularProgress style={{ marginLeft: '45%' }} />
                </Grid>
              )}

              {values.branch && values.appointmentType && values.serviceSubType && values.description && (
                <Grid item className={classes.formItem}>
                  <Typography variant='body2' data-testid='appointment-length-notice'>
                    {StringReplace(i18n.__(copy.AppointmentLengthLabel[meetingMethod || MeetingMethodEnum.ONLINE]), {
                      '%LENGTH%': minutesToUserFacingTimeString(appointmentLength)
                    })}
                  </Typography>
                </Grid>
              )}

              {(availableDates === undefined || availableDates.length > 0) && (
                <Grid item className={classes.formItem}>
                  <ActionButton
                    style={{ marginTop: '16px' }}
                    data-testid='next-button'
                    buttonText={submitButtonText}
                    submitting={isSubmitting}
                    disabled={isSubmitting || !availableSlots || !availableDates}
                    type='submit'
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export const ProsperDetailsForm = observer(BaseProsperDetailsForm)
