import { ActionButton, DialogueDisplay, MeetingMethodEnum, i18n, copy } from '@atbdigitalteam/obs-shared-components'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Form, Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import React from 'react'

import { useBookingStore } from '../../../injectables'
import { PageTitle } from '../../components/PageTitle'
import { DetailsFormValues } from '../../../types'

interface InactiveBranchModifyComponentProps {
  handleSubmit: (values: DetailsFormValues, actions: FormikHelpers<DetailsFormValues>) => void
}

const useStyles = makeStyles({
  divider: {
    maxHeight: '0',
    border: 'solid 0.5px #e4e4e4',
    margin: '0 0 13px 0'
  },
  gridItem: {
    padding: '8px 0'
  },
  label: {
    fontWeight: 600,
    marginBottom: '5px',
    fontFamily: 'inherit',
    fontSize: '14px',
    lineHeight: '20px'
  },
  gridInformation: {
    fontWeight: 400,
    fontFamily: 'inherit',
    fontSize: '14px',
    lineHeight: '24px'
  },
  branchAddress: {
    fontWeight: 400,
    fontFamily: 'inherit',
    fontSize: '11px',
    lineHeight: '20px'
  },
  indent: {
    marginLeft: '1rem'
  },
  buttonGrid: {
    marginTop: '40px'
  },
  dialogueContainer: {
    padding: '24px 0'
  }
})

export const BaseInactiveBranchModifyForm = ({ handleSubmit }: InactiveBranchModifyComponentProps) => {
  const classes = useStyles()
  const { appointmentTimeRange, branchName, branchAddress, meetingMethod, shortDateString, summaryServiceType } =
    useBookingStore()

  return (
    <Formik initialValues={{}} onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <Form data-testid='modify-inactive-form'>
          <Grid item>
            <PageTitle littleTitle='' bigTitle={i18n.__(copy.Modify.InactiveBranchBigTitle)} />
          </Grid>
          <Grid item className={classes.gridItem}>
            <Grid container direction='column' wrap='nowrap' spacing={1} data-testid='appointment-summary'>
              <Grid item>
                <Grid container>
                  <Grid item xs>
                    <Typography className={classes.label} variant='h2'>
                      Appointment summary
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <div className={classes.divider} />
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography className={classes.label}>Location</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Grid item>
                    <Typography noWrap variant='body2' data-testid='appointment-summary-branch-name'>
                      {branchName}
                    </Typography>
                  </Grid>
                  {meetingMethod === MeetingMethodEnum.BRANCH && (
                    <Grid item>
                      <Typography
                        variant='caption'
                        className={classes.branchAddress}
                        data-testid='appointment-summary-branch-address'
                      >
                        {branchAddress}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography className={classes.label}>Details</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant='body2' data-testid='appointment-summary-service-type'>
                      {summaryServiceType}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={2} wrap='nowrap'>
                  <Grid item xs={4}>
                    <Typography className={classes.label}>Date</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      variant='body2'
                      className={classes.gridInformation}
                      data-testid='appointment-summary-date'
                    >
                      {shortDateString}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography className={classes.label}>Time</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      variant='body2'
                      className={classes.gridInformation}
                      data-testid='appointment-summary-time'
                    >
                      {appointmentTimeRange}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.dialogueContainer} data-testid='branch-inactive-message'>
            <DialogueDisplay
              id='business-appointment'
              displayMessage={i18n.__(copy.Modify.InactiveBranchMessage)}
              variant
            />
          </Grid>
          <Grid item className={classes.buttonGrid}>
            <ActionButton
              buttonText={i18n.__(copy.Buttons.Cancel)}
              submitting={isSubmitting}
              disabled={isSubmitting}
              type='submit'
              data-testid='inactive-branch-modify-cancel-button'
            />
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export const InactiveBranchModifyComponent = observer(BaseInactiveBranchModifyForm)
