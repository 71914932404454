export const formatPhoneNumber = (phoneNumber?: string): string | undefined => {
  if (phoneNumber === undefined) {
    return
  }

  // remove all non-numerical values
  const onlyNumbers = phoneNumber.replace(/[^0-9]/g, '')

  let npa
  let nxx
  let station

  if (onlyNumbers.length === 10) {
    npa = onlyNumbers.substr(0, 3)
    nxx = onlyNumbers.substr(3, 3)
    station = onlyNumbers.substr(6, 4)
  } else if (onlyNumbers.length === 11 && onlyNumbers.substr(0, 1) === '1') {
    npa = onlyNumbers.substr(1, 3)
    nxx = onlyNumbers.substr(4, 3)
    station = onlyNumbers.substr(7, 4)
  }

  return npa && nxx && station ? `(${npa}) ${nxx}-${station}` : phoneNumber
}
