import { RouteComponentProps } from '@reach/router'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { getFeedback } from '../../../requests'

import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputLabel from '@material-ui/core/InputLabel'
import LinearProgress from '@material-ui/core/LinearProgress'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import Switch from '@material-ui/core/Switch'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import { useBookingStore } from '../../../injectables'
import { AppointmentOriginEnum } from '@atbdigitalteam/obs-shared-components'

const BaseFeedbackPage = (_props: RouteComponentProps) => {
  interface FeedbackStructure {
    Timestamp: string
    FeedbackText: string
    StarRating: number
    BookingId: string
    BookingStore: string
    BookingOperation: string
  }

  const useStyles = makeStyles({
    root: {
      width: '100%',
      marginTop: 0,
      overflowX: 'auto'
    },
    table: {
      minWidth: 700
    },
    slider: {
      margin: 'inherit',
      width: '20%',
      border: '3px',
      padding: '10px',
      display: 'inline-block'
    },
    flowFilter: {
      margin: 'inherit',
      width: '15%',
      minWidth: '208px',
      border: '3px',
      padding: '10px',
      display: 'inline-block'
    },
    headerCell: {
      backgroundColor: '#0072F0',
      color: 'white'
    },
    tableRow: {
      '&:nth-of-type(odd)': {
        backgroundColor: 'lightgrey'
      }
    }
  })

  const [feedback, setFeedback] = useState<FeedbackStructure[]>([])
  const [onlyComments, setOnlyComments] = useState(false)
  const [selectedFlow, setSelectedFlow] = useState('all')
  const [isFetching, setIsFetching] = useState(false)
  const { setOrigin } = useBookingStore()

  useEffect(() => {
    setOrigin(AppointmentOriginEnum.EFS)
    setIsFetching(true)
    getFeedback(onlyComments).then(response => {
      setFeedback(response.data)
      setIsFetching(false)
    })
  }, [onlyComments])

  const handleOnlyCommentsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOnlyComments(event.target.checked)
  }

  const handleSelectedFlowChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedFlow(event.target.value as string)
  }

  const isSelectedFlow = (feedbackItem: FeedbackStructure, sf: string) => {
    if (sf === 'all') {
      return true
    }
    const storeObject = JSON.parse(feedbackItem.BookingStore)
    if (sf === 'EFS') {
      return !['ATB Prosper', 'ATB GWS'].includes(storeObject.branch.name)
    }

    return storeObject.branch.name === sf
  }

  const makeHumanReadable = (milliseconds: string | number | Date) => {
    const date = new Date(milliseconds)
    return date.toLocaleString()
  }

  const determineFlow = (store: string) => {
    const storeObject = JSON.parse(store)
    if (storeObject.branch.name === 'ATB Prosper') {
      return 'Prosper'
    }
    if (storeObject.branch.name === 'ATB GWS') {
      return 'GWS'
    }
    return `EFS (${storeObject.branch.name || 'No branch selected'})`
  }

  const classes = useStyles()
  return (
    <div>
      <Typography align='center' variant='h2'>
        Production Customer Feedback
      </Typography>
      <div>
        <div className={classes.slider}>
          <FormControlLabel
            control={
              <Switch
                data-testid='only-comments-switch'
                color='primary'
                checked={onlyComments}
                onChange={handleOnlyCommentsChange}
                inputProps={{ 'aria-label': 'onlyComments', color: 'colorPrimary' }}
              />
            }
            label='Only show feedback with comments'
          />
        </div>
        <div className={classes.flowFilter}>
          <FormControl fullWidth>
            <InputLabel id='flow-select-label'>Flow</InputLabel>
            <Select
              labelId='flow-select-label'
              id='flow-select'
              value={selectedFlow}
              defaultValue='All'
              label='Flow'
              onChange={handleSelectedFlowChange}
            >
              <MenuItem value='all'>All</MenuItem>
              <MenuItem value='EFS'>EFS</MenuItem>
              <MenuItem value='ATB Prosper'>ATB Prosper</MenuItem>
              <MenuItem value='ATB GWS'>Group Wealth Services</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      {feedback.length > 0 && (
        <Paper className={classes.root}>
          <Table className={classes.table} data-testid='feedback-table'>
            <TableHead>
              <TableRow>
                <TableCell width='12%' className={classes.headerCell}>
                  Timestamp
                </TableCell>
                <TableCell width='8%' className={classes.headerCell} align='left'>
                  Booking Id
                </TableCell>
                <TableCell width='auto' className={classes.headerCell} align='left'>
                  Flow (Branch)
                </TableCell>
                <TableCell width='9%' className={classes.headerCell} align='left'>
                  Booking Operation
                </TableCell>
                <TableCell width='8%' className={classes.headerCell} align='left'>
                  Score
                </TableCell>
                <TableCell className={classes.headerCell} align='left'>
                  Comments
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching
                ? Array.from(Array(100).keys()).map((_, index) => (
                    <TableRow className={classes.tableRow} key={`loading-row-${index}`}>
                      <TableCell colSpan={100}>
                        <LinearProgress />
                      </TableCell>
                    </TableRow>
                  ))
                : feedback!
                    .filter(fi => isSelectedFlow(fi, selectedFlow))
                    .map(fb => (
                      <TableRow key={fb.Timestamp} className={classes.tableRow}>
                        <TableCell component='th' scope='row'>
                          {makeHumanReadable(fb.Timestamp)}
                        </TableCell>
                        <TableCell align='left'>{fb.BookingId}</TableCell>
                        <TableCell align='left'>{determineFlow(fb.BookingStore)}</TableCell>
                        <TableCell align='left'>{fb.BookingOperation}</TableCell>
                        <TableCell align='left'>{fb.StarRating}</TableCell>
                        <TableCell align='left'>{fb.FeedbackText}</TableCell>
                      </TableRow>
                    ))}
            </TableBody>
          </Table>
        </Paper>
      )}
    </div>
  )
}

export const FeedbackPage = observer(BaseFeedbackPage)
