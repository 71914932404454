export const minutesToUserFacingTimeString = (minutes: number): string => {
  const hours = Math.floor(minutes / 60)
  const leftoverMinutes = minutes % 60
  const multipleHours = hours > 1 ? 's' : ''

  let userFacingTimeString = ''

  userFacingTimeString += hours > 0 ? `${hours} hour${multipleHours}` : ''
  userFacingTimeString += hours > 0 && leftoverMinutes > 0 ? ' and ' : ''
  userFacingTimeString += leftoverMinutes > 0 ? `${leftoverMinutes} minutes` : ''
  return userFacingTimeString
}
