import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import UpdateIcon from '@material-ui/icons/Update'
import React, { useEffect, useState, ReactElement } from 'react'

export interface HoldingTimerProps {
  durationSeconds: number
  onExpire: () => void
}

const useStyles = makeStyles({
  root: {
    width: 'inherit',
    margin: 0,
    padding: 0,
    backgroundColor: '#F7F7F7',
    borderLeft: '16px solid #FF7F30'
  },
  iconContainer: {
    display: 'flex'
  },
  expired: {
    borderLeft: '16px solid #EB0042'
  }
})

export const HoldingTimer = ({ durationSeconds, onExpire }: HoldingTimerProps): ReactElement => {
  const [seconds, setSeconds] = useState(durationSeconds)
  const [expired, setExpired] = useState(false)

  const classes = useStyles()

  useEffect(() => {
    let timeout: NodeJS.Timeout

    if (!expired) {
      if (seconds > 0) {
        timeout = global.setTimeout(() => setSeconds(seconds - 1), 1000)
      } else {
        setExpired(true)
        onExpire()
      }
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [expired, seconds, onExpire])

  const minutes = Math.round(Math.floor(seconds / 60))
  const secondsInMinute = seconds % 60
  const expiredText = 'You can still try to complete this appointment, but this time slot may no longer be available.'
  const activeText = `We are holding the time slot for you for ${minutes}:${
    secondsInMinute < 10 ? `0${secondsInMinute}` : secondsInMinute
  } minutes`

  return (
    <Grid
      data-testid='holding-timer'
      container
      alignItems='center'
      spacing={2}
      className={`${classes.root} ${expired ? classes.expired : ''}`}
    >
      <Grid item className={classes.iconContainer}>
        <UpdateIcon />
      </Grid>
      <Grid item xs>
        <Typography variant='caption'>{expired ? expiredText : activeText}</Typography>
      </Grid>
    </Grid>
  )
}
