import { UIState } from '../styles'
import { GrowTheme } from './types'

export const stateStyles = (theme: GrowTheme) => ({
  [UIState.Error]: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.error.contrastText
  },
  [UIState.Info]: { backgroundColor: theme.status.info },
  [UIState.Processing]: { backgroundColor: theme.status.processing },
  [UIState.Success]: { backgroundColor: theme.status.success },
  [UIState.Warning]: {
    backgroundColor: theme.status.warning,
    color: theme.palette.text.primary
  }
})
