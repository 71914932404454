import { MeetingMethodEnum } from '@atbdigitalteam/obs-shared-components'
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom'
import PermPhoneMsgIcon from '@material-ui/icons/PermPhoneMsg'
import VideocamIcon from '@material-ui/icons/Videocam'
import React from 'react'

export const MeetingComponents = (style: string, meetingMethod?: MeetingMethodEnum) => {
  switch (meetingMethod) {
    case MeetingMethodEnum.BRANCH:
      return {
        icon: <MeetingRoomIcon className={style} />,
        text: 'In-branch appointment'
      }
    case MeetingMethodEnum.ONLINE:
      return {
        icon: <VideocamIcon className={style} />,
        text: 'Online appointment'
      }
    case MeetingMethodEnum.PHONE:
      return {
        icon: <PermPhoneMsgIcon className={style} />,
        text: 'Phone appointment'
      }
    default:
      return {
        icon: <></>,
        text: ''
      }
  }
}
