import { FeedbackOperationEnum } from '@atbdigitalteam/obs-shared-components'
import { ApiRequest } from './ApiRequest'
import { QueryParams } from './QueryParams'

const API_SERVER = process.env.API_SERVER || ''
const BASE_PATH = `${API_SERVER}/api`

export const submitFeedback = (
  feedbackComments: string,
  feedbackRating: number,
  operation: FeedbackOperationEnum,
  bookingStore: string,
  bookingId?: string
) => {
  const url = `${BASE_PATH}/feedback`
  const data = {
    bookingId,
    feedbackRating,
    feedbackComments,
    operation,
    bookingStore
  }

  return new ApiRequest().post(url).withData(data).send()
}

export const getFeedback = (onlyComments: boolean) => {
  const queryParams = new QueryParams({
    onlyComments
  })
  const url = `${BASE_PATH}/feedback?${queryParams.toString()}`
  return new ApiRequest().get(url).send()
}
