import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import { google, ics, outlook, yahoo, CalendarEvent } from 'calendar-link'
import moment from 'moment-timezone'
import React from 'react'

const DATE_TIME_WITH_OFFSET = 'YYYY-MM-DD[T]HH:mm:ssZ'

const useStyles = makeStyles({
  list: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, auto)',
    gridGap: '4px',
    padding: '0',
    marginLeft: '8px'
  },
  listItem: {
    padding: '0',
    '&::before': {
      content: '"•"',
      paddingRight: '4px',
      fontSize: '14px',
      fontWeight: 'bold'
    },
    '&:first-child::before': {
      content: 'none'
    }
  }
})

export interface Event {
  title: string
  description: string
  duration: number // number of minutes
  startDateTime: Date
  location?: string
}

enum Calendar {
  Google = 'Google',
  Outlook = 'Outlook',
  Apple = 'Apple',
  Yahoo = 'Yahoo'
}

const convertHtmlToText = (text: string) => text.replace(/<b>|<\/b>/g, '').replace(/<br>/g, '\n')

const generateLink = (calendar: Calendar, event: Event) => {
  const data: CalendarEvent = {
    title: event.title,
    description: event.description,
    location: event.location,
    start: moment(event.startDateTime).tz('UTC').format(DATE_TIME_WITH_OFFSET),
    duration: [event.duration, 'minutes']
  }

  switch (calendar) {
    case Calendar.Google:
      return google(data)
    case Calendar.Outlook:
      return outlook(data)
    case Calendar.Yahoo:
      data.description = convertHtmlToText(event.description)
      return yahoo(data)
    default:
      data.description = convertHtmlToText(event.description)
      data.location = event.location?.replace(/,/g, '\\,')
      return ics(data)
  }
}

export const AddToCalendar = ({ event }: { event?: Event }) => {
  const { list, listItem } = useStyles()

  const options = [Calendar.Google, Calendar.Outlook, Calendar.Apple, Calendar.Yahoo]

  return event ? (
    <Grid container direction='row' data-testid='add-to-calendar'>
      <Grid item>
        <Typography variant='body2' display='inline' noWrap data-testid='add-to-calendar-label'>
          Add to calendar:
        </Typography>
      </Grid>
      <Grid item>
        <List className={list}>
          {options.map(item => (
            <ListItem key={item} className={listItem}>
              <Typography variant='body2' display='inline' align='left'>
                <Link
                  href={generateLink(item, event)}
                  target='_blank'
                  rel='noreferrer'
                  data-testid={`add-to-calendar-${item.toLowerCase()}`}
                  style={{ textDecoration: 'underline' }}
                >
                  {item}
                </Link>
              </Typography>
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  ) : null
}
