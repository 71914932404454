import {
  AppointmentOriginEnum,
  AppointmentTypeEnum,
  DayOfWeekEnum,
  LoanAmountEnum,
  TimeOfDayEnum
} from '@atbdigitalteam/obs-shared-components'
import { OBSLiteOrigins } from './constants'

// Note that the order of this object matters, otherwise the wrong page can be returned.
const WorkflowPageNames = {
  Details: 'Book',
  Summary: 'Summary',
  Confirmation: 'Confirmation',
  'Find-a-location': 'Find-a-location',
  'Find-a-time': 'Find-a-time',
  Unknown: 'Unknown'
}

const PageNames = {
  Modify: ['Modify'],
  Cancel: ['Cancel'],
  Book: ['Book', 'Obs', 'Gws', 'Prosper', 'Find-a-location', 'Find-a-time', 'Summary', 'Confirmation'],
  Unknown: ['Unknown']
}

export function secondsSinceTime(startTime: Date) {
  return Math.floor((new Date().getTime() - startTime.getTime()) / 1000)
}

export function convertClientType(clientType?: AppointmentTypeEnum) {
  if (clientType === AppointmentTypeEnum.PERSONAL) {
    return 'Personal banking'
  }
  return 'Business banking'
}

export function convertBoolean(boolIn: unknown) {
  return boolIn ? 'Yes' : 'No'
}

export function convertLoanAmount(loanAmount?: LoanAmountEnum) {
  if (loanAmount) {
    return `${loanAmount} 100K`
  } else {
    return 'not applicable'
  }
}

export const currentWorkflowPage = () => {
  const url = window.location.href
  const current_page = currentPage()
  if (current_page === 'Modify' || current_page === 'Cancel') {
    return 'not applicable'
  } else {
    for (const page in WorkflowPageNames) {
      if (url.includes(WorkflowPageNames[page].toLowerCase())) {
        return page
      }
    }
    return 'Unknown'
  }
}

export const getWorkflow = (origin?: AppointmentOriginEnum) => {
  if (!origin) {
    return undefined
  } else if (OBSLiteOrigins.includes(origin)) {
    return 'OBS Lite'
  } else if (Object.values(AppointmentOriginEnum).includes(origin)) {
    return origin
  } else {
    return undefined
  }
}

export const getWorkflowTeam = (origin?: AppointmentOriginEnum) => {
  if (!origin) {
    return undefined
  } else if (getWorkflow(origin) === 'OBS Lite') {
    return `${origin} team`
  } else {
    return undefined
  }
}

export const currentPage = () => {
  const url = window.location.href

  for (const page in PageNames) {
    for (const value of PageNames[page]) {
      if (url.includes(value.toLowerCase())) {
        return page
      }
    }
  }
  return 'Unknown'
}

export const timeRange = (time: string, type: string) => {
  const hour: number = +time.split(':')[0]

  const splitEvening = 5 // evening starts at 5pm

  if (type === 'am') {
    return 'Morning'
  } else {
    if (hour === 12 || hour < splitEvening) {
      return 'Afternoon'
    }
    return 'Evening'
  }
}

export const timeOfDaySet = (timeOfDay?: TimeOfDayEnum) => {
  return timeOfDay !== TimeOfDayEnum.ANY ? 'Yes' : 'No'
}

export const dayOfWeekSet = (dayOfWeek?: DayOfWeekEnum) => {
  return dayOfWeek !== DayOfWeekEnum.ANY ? 'Yes' : 'No'
}
