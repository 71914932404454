import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'

import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { ClassNameMap } from '@material-ui/styles'

import React, { ReactNode } from 'react'
export interface ModalProps {
  open: boolean
  onClose?: () => void
  modalTitle: string
  modalBody?: ReactNode
  modalFooter?: ReactNode
  modalStyle: () => ClassNameMap
  defaultDividers?: boolean
  displayCloseButton?: boolean
}

export const Modal = ({
  open,
  onClose,
  displayCloseButton = true,
  modalTitle,
  modalBody,
  modalFooter,
  modalStyle,
  defaultDividers
}: ModalProps) => {
  const { heading, closeButton, body, confirmButton, orangeDivider } = modalStyle()

  return (
    <React.Fragment>
      <Dialog open={open} onClose={onClose} maxWidth='sm' data-testid='modal'>
        <MuiDialogTitle style={{ paddingTop: '24px' }}>
          <Typography variant='body1' className={heading} data-testid='modal-title'>
            {modalTitle}
          </Typography>
          {displayCloseButton && (
            <IconButton data-testid='close-icon' className={closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          )}
        </MuiDialogTitle>
        {!defaultDividers && <div className={orangeDivider} />}
        <DialogContent dividers={defaultDividers}>
          <Typography variant='body1' data-testid='modal-body' className={body}>
            {modalBody}
          </Typography>
          <DialogActions className={confirmButton}>{modalFooter}</DialogActions>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}
