import { Features, OutageMessage } from '@atbdigitalteam/obs-shared-components'
import { ThemeProvider } from '@material-ui/core/styles'
import { useLocation } from '@reach/router'
import { SnackbarProvider } from 'notistack'
import React, { useEffect, useState, ReactElement } from 'react'

import { AppRouter, routes } from './routing'
import { NotificationHandler } from './modules'
import { Header } from './modules/components/Header'
import { useBranchStore, useFeatureStore } from './injectables/'
import { getFeatures } from './requests/features'
import { googleMapsHealth } from './requests/googleMapsHealth'
import { buildAtbTheme } from './themes'

export const AppointmentApp = (): ReactElement => {
  const { initFeatureStore, obsOutageAlert } = useFeatureStore()
  const [loadingFeatures, setLoadingFeatures] = useState<boolean>(true)
  const { initBranchStore, setGoogleMapsDown } = useBranchStore()
  const location = useLocation()

  useEffect(() => {
    getFeatures()
      .then((res: Features) => {
        const FEATURE_TOGGLES = 'ft'
        const featureOverrides =
          new URLSearchParams(location.search).get(FEATURE_TOGGLES) || sessionStorage.getItem(FEATURE_TOGGLES)

        if (featureOverrides) {
          sessionStorage.setItem(FEATURE_TOGGLES, featureOverrides)
          featureOverrides.split(',').forEach(item => {
            const feature = item.trim()

            if (typeof res[feature] === 'boolean' || !res[feature]) {
              res[feature] = true
            } else if (res[feature].message) {
              res[feature].show = true
            }
          })
        }

        initFeatureStore(res)
      })
      .finally(() => setLoadingFeatures(false))

    googleMapsHealth().then(data => {
      if (!data.gmgcc) {
        initBranchStore()
        setGoogleMapsDown(true)
      }
    })
  }, [location])

  return (
    <ThemeProvider theme={buildAtbTheme()}>
      <SnackbarProvider maxSnack={3} preventDuplicate>
        <NotificationHandler />
        {location.pathname !== routes.root.path && <Header />}
        {loadingFeatures ? null : obsOutageAlert.show ? (
          <div tabIndex={0} id='main-content'>
            <OutageMessage outageHeader={obsOutageAlert.title} outageMessage={obsOutageAlert.message} />
          </div>
        ) : (
          <AppRouter />
        )}
      </SnackbarProvider>
    </ThemeProvider>
  )
}
