import { amber, blueGrey, common, green, lightBlue, red } from '@material-ui/core/colors'
import { createTheme } from '@material-ui/core/styles'
import { GrowTheme } from '../types'
import { hexToRgba } from '../utils'

export const buildAtbTheme = (): GrowTheme => {
  const primary = {
    main: '#0072F0',
    dark: '#0053DB',
    contrastText: common.white
  }
  const secondary = {
    light: '#f9f7ee',
    main: '#FF7F30'
  }
  const themeGrey = blueGrey

  return {
    ...createTheme({
      palette: {
        primary,
        secondary,
        error: {
          main: red[500]
        }
      },
      overrides: {
        MuiButton: {
          root: {
            textTransform: 'none'
          },
          contained: { borderRadius: '22px' },
          containedSizeSmall: { borderRadius: '15px' },
          containedSizeLarge: { borderRadius: '22px' },
          outlined: {
            borderWidth: '2px',
            borderRadius: '22px',
            '&:hover': { borderWidth: '2px' }
          },
          outlinedSizeSmall: {
            borderWidth: '2px',
            borderRadius: '15px'
          },
          outlinedSizeLarge: {
            borderWidth: '2px',
            borderRadius: '22px'
          },
          outlinedPrimary: {
            '&:hover': { borderWidth: '2px' }
          },
          outlinedSecondary: {
            '&:hover': { borderWidth: '2px' }
          }
        },
        MuiFilledInput: {
          root: {
            backgroundColor: '#ffffff',
            '&:hover': {
              backgroundColor: '#ffffff'
            },
            '&$focused': {
              backgroundColor: '#ffffff',
              borderColor: '#0072F0'
            },
            color: '#44444D'
          },
          underline: {
            '&:before': {
              borderBottom: `1px solid ${themeGrey[50]}`
            },
            '&:hover:not($disabled):not($focused):not($error):before': {
              borderBottom: `2px solid ${themeGrey[50]}`
            },
            '&:after': {
              borderBottom: `2px solid ${primary.main}`
            }
          },
          inputMarginDense: {
            // Note: These need to be separated out since MUI has `padding-top` set, and will override a `padding`
            // declaration here
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 16,
            paddingRight: 16
          },
          input: {
            fontSize: '16px',
            paddingTop: 10,
            paddingBottom: 9,
            paddingLeft: 12,
            paddingRight: 12
          }
        },
        MuiSelect: {
          select: {
            '&:focus': {
              backgroundColor: '#ffffff'
            }
          }
        },
        MuiFormGroup: {
          row: {
            backgroundColor: hexToRgba(themeGrey[50], 0.5),
            paddingRight: 16,
            paddingLeft: 16,
            justifyContent: 'space-evenly',
            borderRadius: 4,
            marginTop: 8
          }
        },
        MuiFormLabel: {
          root: {
            fontSize: 14
          }
        },
        MuiFormControlLabel: {
          label: {
            fontSize: 14
          }
        },
        MuiRadio: {
          root: {
            padding: 6,
            margin: '4px 0'
          }
        },
        MuiTypography: {
          gutterBottom: {
            marginBottom: 8
          }
        },
        MuiFormHelperText: {
          contained: {
            marginLeft: 0,
            marginRight: 0
          }
        },
        MuiPickersDay: {
          day: {
            color: '#0072F0'
          }
        },
        MuiMenu: {
          paper: {
            marginLeft: '1px'
          }
        }
      },
      typography: {
        fontFamily: 'Inter', // applies to all typography
        // specific material-ui typography variants we want in other fonts
        h1: { fontFamily: 'ATB TT Norms' },
        h2: { fontFamily: 'ATB TT Norms' },
        h3: { fontFamily: 'ATB TT Norms' },
        h4: { fontFamily: 'ATB TT Norms' },
        h5: {
          fontFamily: 'Inter',
          fontWeight: 500
        },
        h6: { fontFamily: 'Inter' },
        allVariants: {
          color: '#44444D'
        }
      }
    }),
    custom: {
      navigation: {
        headerTextColor: common.white,
        logo: {
          height: '36px',
          width: '52px'
        },
        navTextColor: common.white,
        defaultLinkColor: common.white,
        borderBottom: '1px solid ${darkestBlue}'
      },
      sidebar: {
        dividerColor: secondary.main,
        catalog: {
          backgroundColor: '#f6f6f6'
        }
      },
      workflow: {
        backButtonColor: 'primary',
        footer: {
          background: common.white,
          borderColor: 'rgba(0, 0, 0, 0.12)'
        },
        nextButtonColor: 'primary'
      }
    },
    status: {
      warning: amber[600],
      success: green[500],
      info: themeGrey[500],
      processing: lightBlue[500]
    }
  }
}
