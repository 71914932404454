import {
  AppointmentTypeEnum,
  Branch,
  DayOfWeekEnum,
  LoanAmountEnum,
  ProficiencyEnum,
  TimeOfDayEnum
} from '@atbdigitalteam/obs-shared-components'
import { LatLongObject } from '../modules/components/LocationSearch'
import { ApiRequest } from './ApiRequest'
import { QueryParams } from './QueryParams'

const API_SERVER = process.env.API_SERVER || ''
const BASE_PATH = `${API_SERVER}/api/branches`

export interface PaginatedBranchListResults {
  nextIndex: number
  totalBranches: number
  data: Branch[]
}

const constructLatLngString = (latLng?: LatLongObject): string => {
  if (latLng) {
    return `${latLng.latitude},${latLng.longitude}`
  }
  return ''
}

export const getPaginatedBranchList = (
  startIndex: number,
  numberOfResults: number,
  latLng?: LatLongObject,
  onlyAvailable?: boolean,
  appointmentType?: AppointmentTypeEnum,
  proficiency?: ProficiencyEnum,
  loanAmount?: LoanAmountEnum,
  dayOfWeek?: DayOfWeekEnum,
  timeOfDay?: TimeOfDayEnum
): Promise<PaginatedBranchListResults> => {
  const queryParams = new QueryParams({
    includeAvailability: true,
    startIndex,
    numberOfResults
  })

  if (latLng) queryParams.append({ latlng: constructLatLngString(latLng) })
  if (onlyAvailable) queryParams.append({ onlyAvailable })
  if (appointmentType) queryParams.append({ appointmentType })
  if (proficiency !== undefined && proficiency >= 0) queryParams.append({ proficiency })
  if (loanAmount) queryParams.append({ loanAmount })
  if (dayOfWeek) queryParams.append({ dayOfWeek })
  if (timeOfDay) queryParams.append({ timeOfDay })

  const url = `${BASE_PATH}?${queryParams.toString()}`

  return new ApiRequest()
    .get(url)
    .send()
    .then(response => {
      return response.data
    })
}

export const getBranchList = (): Promise<Branch[]> => {
  const url = BASE_PATH

  return new ApiRequest()
    .get(url)
    .send()
    .then(response => {
      return response.data
    })
}

export const getBranch = (branchId: string): Promise<Branch> => {
  const url = BASE_PATH

  return new ApiRequest()
    .get(url + `/${branchId}`)
    .send()
    .then(response => {
      return response.data
    })
}
