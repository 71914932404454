import { TimeSlot } from '@atbdigitalteam/obs-shared-components'

export const computeTimeSlotRange = (timeSlot: TimeSlot, appointmentLength: number, timezoneLabel: string): string => {
  const startTime = `${timeSlot.time}${timeSlot.type.toLowerCase()}`
  const [startHours, startMinutes] = timeSlot.time.split(':').map(numberString => Number(numberString))
  let endHours = startHours
  let endMinutes = startMinutes
  let endType = timeSlot.type.toLowerCase()

  endMinutes += appointmentLength

  while (endMinutes >= 60) {
    endHours += 1
    endMinutes -= 60
  }

  if (endHours >= 12 && startHours < 12) {
    endType = endType === 'am' ? 'pm' : 'am'
  }

  if (endHours > 12) {
    endHours -= 12
  }

  const endTime = `${endHours}:${endMinutes === 0 ? '00' : endMinutes}${endType}`
  return `${startTime} - ${endTime} ${timezoneLabel}`
}
