import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { ReactElement } from 'react'

export interface BulletListProps {
  items?: Array<string | ReactElement>
}

const useStyles = makeStyles({
  listItems: {
    marginTop: '8px'
  }
})

export const BulletList = ({ items }: BulletListProps): ReactElement => {
  const { listItems } = useStyles()

  return (
    <Typography variant='body2' component='div'>
      <ul className={listItems} data-testid='bullet-list'>
        {items && items.map((item: string | ReactElement, index) => <li key={index}>{item}</li>)}
      </ul>
    </Typography>
  )
}
