import { useEffect, useRef, useState } from 'react'
import { v4 as createUUID } from 'uuid'
import { navigate } from '@reach/router'
import {
  AuthorizationListener,
  AuthorizationNotifier,
  AuthorizationRequest,
  AuthorizationServiceConfiguration,
  BaseTokenRequestHandler,
  DefaultCrypto,
  FetchRequestor,
  GRANT_TYPE_AUTHORIZATION_CODE,
  LocalStorageBackend,
  RedirectRequestHandler,
  StringMap,
  TokenRequest
} from '@openid/appauth'

import { NoHashQueryStringUtils } from '../utils/noHashQueryStringUtils'
import { routes } from '../../routing'
import { AuthSource } from '../../types'

export const SESSION_ID = 'session_id'

export const AuthClientID = {
  [AuthSource.retail]: 'BOOKING',
  [AuthSource.business]: 'BUSBOOKING'
}

export interface PingAuthContext {
  authRequest: (source?: AuthSource) => void
  authListener: (source?: AuthSource) => AuthorizationListener
  completeAuthorizationRequest: () => void
  error?: string
  logout: (url: string) => void
  token?: string
}

export const usePingAuth = (branchId = ''): PingAuthContext => {
  const sessionId = useRef<string>('')
  const [token, setToken] = useState<string>()
  const [error, setError] = useState<string>()

  const opServer = process.env.PING_OP_SERVER || ''

  const authorizationHandler = new RedirectRequestHandler(
    new LocalStorageBackend(),
    new NoHashQueryStringUtils(),
    window.location,
    new DefaultCrypto()
  )
  const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor())

  useEffect(() => {
    sessionId.current = sessionStorage.getItem(SESSION_ID) || ''
    if (!sessionId.current) {
      sessionId.current = createUUID().replace(/-/g, '')
      sessionStorage.setItem(SESSION_ID, sessionId.current)
    }
  }, [])

  const logout = (url: string) => navigate(`${opServer}/idp/startSLO.ping?TargetResource=${url}`)

  const completeAuthorizationRequest = () => {
    authorizationHandler.completeAuthorizationRequestIfPossible()
  }

  const authListener = (source?: AuthSource) => {
    const notifier = new AuthorizationNotifier()
    authorizationHandler.setAuthorizationNotifier(notifier)

    const listener: AuthorizationListener = (request, response) => {
      if (response && source) {
        const extras: StringMap = {
          cmid: sessionId.current
        }

        if (request && request.internal) {
          extras.code_verifier = request.internal.code_verifier
        }

        const tokenRequest = new TokenRequest({
          client_id: AuthClientID[source],
          redirect_uri: `${window.location.origin}${window.location.pathname}`,
          grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
          code: response.code,
          extras
        })

        AuthorizationServiceConfiguration.fetchFromIssuer(opServer, new FetchRequestor())
          .then(oResponse => tokenHandler.performTokenRequest(oResponse, tokenRequest))
          .then(oResponse => {
            setToken(oResponse.accessToken)
          })
          .catch(error => {
            setError(error)
          })
      }
    }

    notifier.setAuthorizationListener(listener)
    return listener
  }

  const authRequest = (source?: AuthSource) => {
    if (source) {
      AuthorizationServiceConfiguration.fetchFromIssuer(opServer, new FetchRequestor())
        .then(response => {
          const authRequest = new AuthorizationRequest({
            client_id: AuthClientID[source],
            redirect_uri: `${location.origin}${routes.details.path}${branchId}`,
            scope: 'openid profile email',
            response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
            extras: { cmid: sessionId.current }
          })
          authorizationHandler.performAuthorizationRequest(response, authRequest)
        })
        .catch(error => {
          setError(error)
        })
    }
  }

  return { authListener, authRequest, completeAuthorizationRequest, error, logout, token }
}
