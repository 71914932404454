import {
  Advisor,
  AppointmentOriginEnum,
  AppointmentSubTypeEnum,
  AppointmentTypeEnum,
  LoanAmountEnum,
  MeetingMethodEnum,
  ProficiencyEnum,
  ProvinceEnum,
  TimeSlot
} from '@atbdigitalteam/obs-shared-components'
import { format } from 'date-fns'
import { ApiRequest } from './ApiRequest'
import { QueryParams } from './QueryParams'

const API_SERVER = process.env.API_SERVER || ''
const BASE_PATH = `${API_SERVER}/api/calendars`

export const getAvailability = (
  transit: string,
  date: Date,
  appointmentType: AppointmentTypeEnum,
  serviceSubType: AppointmentSubTypeEnum,
  proficiencyLevel: ProficiencyEnum,
  meetingMethod?: MeetingMethodEnum,
  advisor?: Advisor,
  bookingId?: string,
  loanAmount?: LoanAmountEnum,
  origin?: AppointmentOriginEnum
): Promise<TimeSlot[]> => {
  const formattedDate = format(date, 'yyyy-MM-dd')
  const branchTransit = /^[0-9]{3}$/.exec(transit)
  if (branchTransit === null) {
    throw Error('Transit cannot be null')
  }

  const queryParams = new QueryParams({
    date: formattedDate,
    appointmentType,
    proficiency: proficiencyLevel,
    serviceSubType
  })

  if (advisor) queryParams.append({ advisorEmail: advisor.email })
  if (meetingMethod) queryParams.append({ meetingMethod })
  if (bookingId) queryParams.append({ bookingId })
  if (loanAmount) queryParams.append({ loanAmount })
  if (origin) queryParams.append({ origin })

  return new ApiRequest()
    .get(`${BASE_PATH}/${branchTransit[0]}?${queryParams.toString()}`)
    .send()
    .then(response => {
      return response.data
    })
}

export const getAvailableDates = (
  transit: string,
  startDate: Date,
  endDate: Date,
  appointmentType: AppointmentTypeEnum,
  serviceSubType: AppointmentSubTypeEnum,
  proficiencyLevel: ProficiencyEnum,
  meetingMethod?: MeetingMethodEnum,
  advisor?: Advisor,
  bookingId?: string,
  loanAmount?: LoanAmountEnum,
  origin?: AppointmentOriginEnum
): Promise<string[]> => {
  const formattedStartDate = format(startDate, 'yyyy-MM-dd')
  const formattedEndDate = format(endDate, 'yyyy-MM-dd')
  const branchTransit = /^[0-9]{3}$/.exec(transit)
  if (branchTransit === null) {
    throw Error('Transit cannot be null')
  }

  const queryParams = new QueryParams({
    appointmentType,
    endDate: formattedEndDate,
    proficiency: proficiencyLevel,
    serviceSubType,
    startDate: formattedStartDate
  })

  if (advisor) queryParams.append({ advisorEmail: advisor.email })
  if (meetingMethod) queryParams.append({ meetingMethod })
  if (bookingId) queryParams.append({ bookingId })
  if (loanAmount) queryParams.append({ loanAmount })
  if (origin) queryParams.append({ origin })

  return new ApiRequest()
    .get(`${BASE_PATH}/${branchTransit[0]}/dates?${queryParams.toString()}`)
    .send()
    .then(response => {
      return response.data
    })
}

export const holdTimeslot = async (
  transit: string,
  date: Date,
  time: string,
  type: string,
  appointmentType: AppointmentTypeEnum,
  serviceSubType: AppointmentSubTypeEnum,
  proficiencyLevel: ProficiencyEnum,
  meetingMethod?: MeetingMethodEnum,
  bookingId?: string,
  advisorEmail?: string,
  origin?: AppointmentOriginEnum
) => {
  const formattedDate = format(date, 'yyyy-MM-dd')
  const holdUrl = `${BASE_PATH}/${transit}/events/hold`
  const body = {
    date: formattedDate,
    time,
    type,
    appointmentType,
    serviceSubType,
    proficiency: proficiencyLevel,
    bookingId,
    advisorEmail,
    meetingMethod,
    origin
  }

  return new ApiRequest().post(holdUrl).withData(body).send()
}

export const releaseTimeslot = (transit: string, eventId: string, bookingId: string) => {
  const url = `${BASE_PATH}/${transit}/events/${eventId}/hold`
  const body = {
    bookingId
  }

  return new ApiRequest().delete(url).withData(body).send()
}

export const confirmAppointment = (
  transit: string,
  eventId: string,
  appointmentType: AppointmentTypeEnum,
  serviceSubType: AppointmentSubTypeEnum,
  customerEmail: string,
  employerName: string,
  province: ProvinceEnum,
  firstName: string,
  lastName: string,
  branchLocation: string,
  bookingId: string,
  description: string,
  businessName: string,
  phoneNumber: string,
  caslConsent: boolean,
  preferredContactMethod: string,
  reminderRequested: boolean,
  existingAppointment = false,
  existingCustomer?: boolean,
  meetingMethod?: MeetingMethodEnum,
  advisor?: Advisor,
  bpId?: string,
  loginType?: string,
  origin?: AppointmentOriginEnum,
  caseId?: string,
  loanAmount?: LoanAmountEnum
) => {
  const url = `${BASE_PATH}/${transit}/events/${eventId}`
  const body = {
    appointmentType,
    serviceSubType,
    customerEmail,
    employerName,
    province,
    firstName,
    lastName,
    branchLocation,
    bookingId,
    description,
    businessName,
    phoneNumber,
    existingCustomer,
    caslConsent,
    advisor,
    bpId,
    preferredContactMethod,
    existingAppointment,
    meetingMethod,
    loginType,
    reminderRequested,
    origin,
    caseId,
    loanAmount: serviceSubType === AppointmentSubTypeEnum.LOANS_LINES_OF_CREDIT ? loanAmount : undefined
  }

  if (!advisor) {
    delete body.advisor
  }

  return new ApiRequest().post(url).withData(body).send()
}

export const modifyAppointment = (
  appointmentType: AppointmentTypeEnum,
  customerEmail: string,
  description: string,
  eventId: string,
  serviceSubType: AppointmentSubTypeEnum,
  transit: string,
  reminderRequested: boolean,
  meetingMethod?: MeetingMethodEnum,
  loanAmount?: LoanAmountEnum,
  province?: ProvinceEnum,
  existingCustomer?: boolean
) => {
  const url = `${BASE_PATH}/${transit}/events/${eventId}`
  const body = {
    appointmentType,
    customerEmail,
    description: description || '',
    serviceSubType: serviceSubType || '',
    meetingMethod,
    reminderRequested,
    loanAmount: serviceSubType === AppointmentSubTypeEnum.LOANS_LINES_OF_CREDIT ? loanAmount : undefined,
    province,
    existingCustomer
  }

  return new ApiRequest().patch(url).withData(body).send()
}
