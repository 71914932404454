import { ActionButton } from '@atbdigitalteam/obs-shared-components'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { navigate } from '@reach/router'
import React, { FunctionComponent } from 'react'
import { clearAllStores } from '../../injectables'
import { getShortBookingId } from '../../utils/bookingId'

export interface LeavePageModalProps {
  open: boolean
  onClose: () => void
  bookingId?: string
  newBookingURL: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      marginTop: 0,
      right: theme.spacing(1),
      top: theme.spacing(0.5),
      color: 'black'
    },
    body: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(10)
    },
    confirmButton: {
      marginTop: theme.spacing(2),
      position: 'absolute',
      right: theme.spacing(1),
      bottom: theme.spacing(3)
    },
    heading: {
      fontWeight: 'bold',
      fontSize: '18px',
      top: theme.spacing(1)
    },
    dividers: {
      padding: '16px 31px'
    }
  })
)

const linkStyle = { textDecoration: 'underline', color: '#0072F0' } as React.CSSProperties
const buttonStyle = {
  textAlign: 'center',
  whiteSpace: 'nowrap',
  paddingLeft: '3em',
  paddingRight: '3em',
  marginTop: '16px'
} as React.CSSProperties

export const LeavePageModal: FunctionComponent<LeavePageModalProps> = ({ open, onClose, bookingId, newBookingURL }) => {
  const { heading, closeButton, body, confirmButton, dividers } = useStyles()

  return (
    <React.Fragment>
      <Dialog open={open} onClose={onClose} maxWidth='sm' data-testid='leave-page-modal'>
        <MuiDialogTitle>
          <Typography variant='body1' className={heading} data-testid='leave-page-modal-title'>
            Where to now?
          </Typography>
          <IconButton data-testid='close-icon' className={closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <DialogContent dividers className={dividers}>
          <Typography variant='body1' data-testid='leave-page-modal-body' className={body}>
            Instead of using the back button, which could impact your appointment booking, you can{' '}
            <Link
              data-testid='edit-link'
              href={bookingId ? `/modify?bookingId=${getShortBookingId(bookingId)}` : '/modify'}
              style={linkStyle}
            >
              edit your appointment
            </Link>
            ,{' '}
            <Link
              data-testid='cancel-link'
              href={bookingId ? `/cancel?bookingId=${getShortBookingId(bookingId)}` : '/cancel'}
              style={linkStyle}
            >
              cancel your appointment
            </Link>
            ,{' '}
            <Link
              data-testid='book-link'
              href={newBookingURL}
              style={linkStyle}
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                const target = event.target as HTMLAnchorElement
                event.preventDefault()
                clearAllStores()
                navigate(target.href)
              }}
            >
              book another appointment
            </Link>
            , or click below to go back to atb.com.
          </Typography>
          <DialogActions className={confirmButton}>
            <ActionButton
              buttonText='Return to atb.com'
              type='submit'
              data-testid='leave-page-modal-btn'
              onClick={() => {
                clearAllStores()
                navigate('https://www.atb.com/')
              }}
              autoFocus
              style={buttonStyle}
            />
          </DialogActions>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}
